import React from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./lib/history";
import { QueryParamProvider } from "use-query-params";
import { UserProvider, LoginGaurd } from "./contexts/UserContext";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import { theme } from "./theme";
import { Login } from "./pages/login";
import { Dashboard } from "./pages/dashboard";
import { Layout } from "./layout";
import { Keys } from "./pages/keys";
import { Billlng } from "./pages/billing";
import { Register } from "./pages/register";
import { OTP } from "./pages/otp";
import { PasswordReset } from "./pages/password-reset";
import { ResetRequest } from "./pages/reset-request";
import { Profile } from "./pages/profile";
import { ErrorBoundary } from "./ErrorBoundary";
import { OrderWizard } from "./pages/order-wizard";
import { NewBucketWizard } from "./pages/order-wizard-new/new-bucket";
import { Bucket } from "./pages/bucket";
import { Support } from "./pages/support";
import { Policies } from "./pages/policies";
import { MountingWizard } from "./pages/mounting-wizard";
import { ScrollToTopOnNavigation } from "./ScrollToTopOnNavigation";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Router history={history}>
        <ScrollToTopOnNavigation />
        <QueryParamProvider ReactRouterRoute={Route}>
          <ErrorBoundary>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/register">
                <Register />
              </Route>
              <Route path="/otp">
                <OTP />
              </Route>
              <Route path="/reset-request">
                <ResetRequest />
              </Route>
              <Route path="/password-reset">
                <PasswordReset />
              </Route>
              <Route
                render={() => {
                  return (
                    <UserProvider>
                      <LoginGaurd>
                        <Layout>
                          <Switch>
                            <Route path="/dashboard">
                              <Dashboard />
                            </Route>
                            <Route path="/keys">
                              <Keys />
                            </Route>
                            <Route path="/billing">
                              <Billlng />
                            </Route>
                            <Route path="/profile">
                              <Profile />
                            </Route>
                            <Route path="/policies">
                              <Policies />
                            </Route>

                            <Route path="/support">
                              <Support />
                            </Route>
                            <Route path="/orders/first">
                              <OrderWizard />
                            </Route>
                            <Route path="/orders/new">
                              <NewBucketWizard />
                            </Route>
                            <Route path="/buckets/:id/:action?">
                              <Bucket />
                            </Route>
                            <Route path="/how-do-i-access-my-bucket">
                              <MountingWizard />
                            </Route>
                            <Redirect from="/" to="/dashboard" />
                          </Switch>
                        </Layout>
                      </LoginGaurd>
                    </UserProvider>
                  );
                }}
              />
            </Switch>
          </ErrorBoundary>
        </QueryParamProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
