import React, { forwardRef } from "react";
import classnames from "classnames";
import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { Box, Heading, Grid, Link } from "@chakra-ui/core";
import { Section } from "../../profile/Shared";
import slaImage from "./sla.png";

export interface SupportSLAProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  margin-top: 1em;
`;

export const SectionContainer = styled(Box)`
  display: grid;
  grid-template-areas: "support";
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-areas: "support";
  }
  grid-gap: 1em;
  img {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const SupportSLA = forwardRef<HTMLDivElement, SupportSLAProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Container {...props} ref={ref} className={classnames("", {}, className)}>
        <Heading as="h3" size="lg" paddingTop="0.5em">
          Support SLA
        </Heading>
        <SectionContainer>
          <Section gridArea="support">
            <Grid padding={["0", "2em"]}>
              <Link
                isExternal
                href="https://support.cloudafrica.net/support/solutions/articles/101000466979-sla-service-level-agreement"
                target="_blank"
              >
                <img
                  alt="Support SLA"
                  src={slaImage}
                  style={{ width: "100%" }}
                />
              </Link>
            </Grid>
          </Section>
        </SectionContainer>
      </Container>
    );
  }
);
