import * as yup from "yup";
import { ValidEmail, NoLeadingOrTrailingSpaces } from "../../lib/schemata";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email address is required")
    .test(ValidEmail)
    .test(NoLeadingOrTrailingSpaces),
  password: yup
    .string()
    .required("Password is required")
    .test(NoLeadingOrTrailingSpaces),
});
