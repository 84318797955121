import React, {
  forwardRef,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useToast, Box } from "@chakra-ui/core";
import { useLoading } from "../../hooks/useLoading";
import { Container } from "../profile/Shared";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import { Loader } from "../shared";
import { NotAuthenticatedError } from "../../lib/api/base";
import { account } from "../../lib/api/billing";
import { StringParam, useQueryParam } from "use-query-params";
import { placeFirstBucketOrder } from "../../lib/api/orders";
import { UserContext } from "../../contexts/UserContext";
import { Redirect } from "react-router";
import { AccountForm } from "./AccountForm";

export interface FirstOrderProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const SectionContainer = styled(Box)`
  display: grid;
  grid-template-areas:
    "basic"
    "address"
    "credit"
    "button";
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-areas:
      "basic address"
      "credit credit"
      "button button";
  }
  grid-gap: 1em;
`;

export const FirstOrder = forwardRef<HTMLDivElement, FirstOrderProps>(
  ({ children, className, ...props }, ref) => {
    const { user } = useContext(UserContext);
    const [tier] = useQueryParam("tier", StringParam);
    const [loading, , withLoading] = useLoading<any>();
    const [hasAccount, setHasAccount] = useState(false);
    const toast = useToast();

    const loadAccount = useCallback(
      () =>
        withLoading(async () => {
          if (!loading.loading && !loading.loaded) {
            try {
              await account();
              setHasAccount(true);
            } catch (e) {
              if (e instanceof NotAuthenticatedError) {
                setHasAccount(false);
              }
            }
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    useEffect(() => {
      loadAccount();
    }, [loadAccount]);

    useEffect(() => {
      if (
        !hasAccount &&
        loading.loaded &&
        tier === "free" &&
        !loading.loading &&
        !loading.error
      ) {
        withLoading(async () => {
          await placeFirstBucketOrder({
            tier: "free",
            account: {
              address: {
                city: "NA",
                street: "NA",
                postalCode: "NA",
                province: "NA",
                extra: "NA",
              },
              companyNumber: "",
              vatNumber: "",
              name: user.email || "",
            },
            tag: "First bucket",
          });
          await loadAccount();

          toast({
            title: "Account created.",
            description: "Your account has successfully been created",
            status: "success",
            duration: 2000,
            position: "top",
            isClosable: true,
          });
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasAccount, tier, loading]);

    if (hasAccount) {
      return <Redirect to="/dashboard" />;
    }

    if (!loading.loaded || loading.loading) {
      return (
        <Container>
          <Loader>fetching account...</Loader>
        </Container>
      );
    }

    return <AccountForm />;
  }
);
