import React, {
    forwardRef,
    useContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { Form, Formik } from "formik";
import { Section } from "../../profile/Shared";
import { creditCardValidators } from "../../../lib/schemata";
import { deleteCreditCard, addCreditCard, creditCard3dsResponse} from "../../../lib/api/billing";
import { UserContext } from "../../../contexts/UserContext";
import { useApiCall } from "../../../hooks/useApiCall";
import { useLoading } from "../../../hooks/useLoading";
import { usePagination } from "../../../hooks/usePagination";
import { useFormOptions } from "../../order-wizard-new/new-bucket/steps/Payment";
import { ValidationError, NotAuthenticatedError } from "../../../lib/api/base";
import { Profile } from "../../../lib/api/user";
import { history } from "../../../lib/history";
import { useQueryParams, NumberParam } from "use-query-params";
import { FormikField, FormikSelect } from "../../shared/forms";
import { Pager } from "../../shared/Pager";
import {
    Heading,
    Input,
    Stack,
    Button,
    useToast,
    Box,
    Alert,
    Text,
    Grid,
    Icon,
    Modal,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalOverlay,
    ModalHeader,
    ModalFooter,
} from "@chakra-ui/core";
import {
    creditCards,
    CreditCard,
} from "../../../lib/api/billing";
const CardStack = styled.div`
    display: grid;
    grid-template-areas:
    "name"
    "number"
    "type"
    "month"
    "year"
    "cvv";
    @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-areas:
    "name name number number"
    "type month year cvv";
    }
    grid-gap: 1em;
`;
const Container = styled.div`
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr;
    margin-top: 1em;
`;

const Row = styled.div`
    box-shadow: ${theme.shadows.md};
    border-radius: ${theme.radii.lg};
    border: solid 1px ${theme.colors.gray[50]};
    padding: 1em;
    cursor: pointer;
    display: grid;
    background-color: #fff;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5em;
    justify-content: space-between;
    h3 {
    margin-bottom: 0.25em;
    }
    &:hover {
    background-color: #fafafa;
    }
    &:active {
    background-color: #f8f8f8;
    }

    @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 1fr 2fr 2fr 2fr auto;
    }
`;

const Column = styled.div<{ textAlign: "left" | "right" }>`
  display: flex;
  flex-direction: column;
  &:last-child {
    text-align: ${(props) => props.textAlign};
  }
  span:first-child {
    font-weight: bold;
    color: ${theme.colors.gray[500]};
  }
`;

export const SectionContainer = styled(Box)`
  display: grid;
  grid-template-areas:
    "basic"
    "credit"
    "button";
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-areas:
      "basic basic"
      "credit credit"
      "button";
  }
  grid-gap: 1em;
`;




export interface ManageCreditCardProps
extends React.HtmlHTMLAttributes<HTMLDivElement> {
}

const newCardSchema = yup.object().shape({
    ...creditCardValidators,
});

const perPage = 10;

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const ManageCreditCards = forwardRef<
  HTMLDivElement,
  ManageCreditCardProps
>(({ children, className, ...props }, ref) => {
  const { user } = useContext(UserContext);
  const query = useQuery();
  const [ccs, , reload] = useApiCall(() => creditCards(), [], []);
  const [ccToDelete, setCardToDelete] = useState<Partial<CreditCard>>({});
  const [search, setSearch] = useState("");
  const [peachId, ] = useState(query.get("id") || "");
  const [peachRedirect, setPeachRedirect] = useState<{url: string; parameters: {name: string; value:string}[]}>({url: "", parameters: []});
  const [submitError, setSubmitError] = useState<{ message?: string }>({});
  const [loading, , withLoading] = useLoading<void>();
  const [deletingCCLoading, , withDeletingCCLoading] = useLoading<void>();
  const { innerWidth: width, innerHeight: height } = window;
  const [paginator, setPaginator] = useQueryParams({
      page: NumberParam,
      perPage: NumberParam,
  });

  const peachForm = document.getElementById('peach-3ds') as HTMLFormElement | null;

  const toast = useToast();

  const filteredCCs = useMemo(() => {
      return ccs
          .filter((cc) =>
              !!search
              ? `${cc.cardholderName}${cc.expiryMonth}/${cc.expiryYear}${cc.lastfour}`
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              : true
          );
  }, [ccs, search]);


  const pagination = usePagination({
      count: filteredCCs.length,
      page: paginator.page || 1,
      rowsPerPage: paginator.perPage || perPage,
      rowsPerPageOptions: [10, 30, 50],
  });

  const options = useFormOptions({ ccs });

  const useInitialFormValues = (user: Partial<Profile>, ccs: CreditCard[]) => {

  return {
      cc_name: "",
      cc_number: "",
      cc_type: "",
      cc_expMonth: "",
      cc_expYear: "",
      cc_cvv: "",
  };
  };

  interface IInitialFormValues extends ReturnType<typeof useInitialFormValues> {}

    const handle3dsResult = useCallback(async() => {
        if (peachId !== null && peachId !== ""){
            try{
                setSubmitError({});
                await creditCard3dsResponse({peachId: peachId});
                await reload();
                toast({
                    title: "Credit Card added.",
                    description: "The new credit card has successfully been added",
                    status: "success",
                    duration: 3000,
                    position: "top",
                    isClosable: true,
                });
            } catch (e) {
                console.log(e);
                if (e instanceof ValidationError) {
                    await reload();
                    return setSubmitError({ message: e.message });
                }
                if (e instanceof NotAuthenticatedError) {
                    toast({
                        title: "Session expired",
                        status: "error",
                        duration: 3000,
                        position: "top",
                    });
                    return history.push("/login");
                }
                return setSubmitError({
                    message: "An unexpected error has occurred",
                });
            }finally{
            }
            }
            
;

    },[toast, peachId, reload]);
    
    const handleSubmitCC = useCallback(
    (values: IInitialFormValues) => {
      withLoading(async () => {
          try {
              setSubmitError({});
              const redirect = await addCreditCard({
                  amount: 0,
                  browserJavaEnabled: window.navigator.javaEnabled(),
                  browserScreenHeight: height,
                  browserScreenWidth: width,
                  browserTimezone: (new Date()).getTimezoneOffset(),
                  creditCard: {
                      cardholderName: values.cc_name,
                      expiryMonth: values.cc_expMonth,
                      expiryYear: values.cc_expYear,
                      lastfour: values.cc_number,
                      type: values.cc_type,
                      cvv: values.cc_cvv,
                  },
              });
              setPeachRedirect(redirect);
           
        } catch (e) {
          console.log(e);
          if (e instanceof ValidationError) {
            return setSubmitError({ message: e.message });
          }
          if (e instanceof NotAuthenticatedError) {
            toast({
              title: "Session expired",
              status: "error",
              duration: 3000,
              position: "top",
            });
            return history.push("/login");
          }
          return setSubmitError({
            message: "An unexpected error has occurred",
          });
        }
      });
    },
    [toast, withLoading, height, width]
  );

    useEffect(() => {
        if (peachForm !== null){
            peachForm.submit();
        }
    }, [peachForm]);

    useEffect(() => {
        handle3dsResult();
    }, [handle3dsResult]);
    
    useEffect(() => {
        setPaginator({ page: 1 });
    }, [setPaginator]);



    const initialFormValues = useInitialFormValues(user, ccs);

    const handleDeleteCC = useCallback(() => {
        withDeletingCCLoading(async () => {
            if (!ccToDelete.id) return;
            try {
                setSubmitError({});
                await deleteCreditCard(ccToDelete.id);
                await reload();
                toast({
                    title: "Credit Card successfully deleted",
                    status: "success",
                    duration: 3000,
                    position: "top",
                });
            } catch (e) {
                setSubmitError({ message: e.message });
                if (e instanceof NotAuthenticatedError) {
                    toast({
                        title: "Session expired",
                        status: "error",
                        duration: 3000,
                        position: "top",
                    });
                    return history.push("/login");
                }
            }
            setCardToDelete({});
        });
    }, [withDeletingCCLoading, ccToDelete, reload, toast]);


    if (peachRedirect.url !== "") {

        return (
            <form method="POST" action={peachRedirect.url} id="peach-3ds">
            {peachRedirect.parameters.map((pm, i) => (
                <input
                    type="hidden"
                    key="pm-{i}"
                    name={pm.name}
                    value={pm.value}
                />
            ))}
                </form>
                );
        
    }

  return (
      <Container {...props} ref={ref} className={classnames("", {}, className)}>
          <div>
              <Grid alignItems={"center"} gap={4}>
                  <div>
                      <Input
                          data-testid="search"
                          placeholder="Search..."
                          value={search}
                          onChange={(e: any) => setSearch(e.target.value)}
                      />
                  </div>
              </Grid>
          </div>
          <Formik
              initialValues={initialFormValues}
              onSubmit={handleSubmitCC}
              validationSchema={newCardSchema}
          >
              <Form>
                  <Heading as="h3" size="lg" paddingBottom="0.5em" paddingTop="0.5em">
                  </Heading>
                  {submitError.message && (
                      <Alert status="error" marginBottom="0.5em">
                          {submitError.message}
                      </Alert>
                  )}
                  <SectionContainer>
                     <Section gridArea="credit">
                          <Heading as="h3" size="md" paddingBottom="0.25em">
                              Add new Credit Card
                          </Heading>
                              <CardStack>
                                  <FormikField<IInitialFormValues>
                                      gridArea="name"
                                      name="cc_name"
                                      label="Cardholder Name"
                                      placeholder="e.g. J Applebee"
                                      isRequired
                                      />
                                   <FormikField<IInitialFormValues>
                                       gridArea="number"
                                       name="cc_number"
                                       label="Card Number"
                                       placeholder="e.g. 4545 5889 1123 5557"
                                       isRequired
                                       />
                                   <FormikSelect<IInitialFormValues>
                                       gridArea="type"
                                       name="cc_type"
                                       label="Card Type"
                                       options={options.ccTypes.all()}
                                       isRequired
                                       />
                                   <FormikSelect<IInitialFormValues>
                                       gridArea="month"
                                       name="cc_expMonth"
                                       label="Expiry Month"
                                       options={options.ccMonths.all()}
                                       isRequired
                                       />
                                   <FormikSelect<IInitialFormValues>
                                       gridArea="year"
                                       name="cc_expYear"
                                       label="Expiry Year"
                                       options={options.ccYears.all()}
                                       isRequired
                                       />
                                   <FormikField<IInitialFormValues>
                                       gridArea="cvv"
                                       name="cc_cvv"
                                       label="CVV"
                                       placeholder="e.g. 456"
                                       isRequired
                                       />
                              </CardStack>
                     </Section>
                     <Stack gridArea="button" alignItems="center">
                         <Button
                             type="submit"
                             isLoading={loading.loading}
                             variantColor="blue"
                         >
                             Add Credit Card
                         </Button>
                     </Stack>
                  </SectionContainer>
                                   </Form>
      </Formik>
      {filteredCCs.slice(pagination.begin, pagination.end)
              .map((cc, i) => (
              <Row key={i} data-testid={`cc-${i}`}>
                  <Column textAlign="left">
                      <span>Card Holder Name</span>
                      <span>{cc.cardholderName}</span>
                  </Column>
                  <Column textAlign="left">
                      <span>Number</span>
                      <span>... {cc.lastfour}</span>
                  </Column>
                  <Column textAlign="left">
                      <span>Expiry</span>
                      <span>{cc.expiryMonth}/{cc.expiryYear}</span>
                  </Column>
                  <Column
                      textAlign="left"
                      data-testid={`delete-cc-${i}`}
                      onClick={() => {
                          setCardToDelete(cc);
                      }
                      }>
                      <Grid justifyItems="flex-end">
                          <span><Icon name="delete" /></span>
                      </Grid>
                  </Column>
              </Row>
          ))}
          {handleDeleteCC && (
              <Modal isOpen={!!ccToDelete.id} onClose={() => setCardToDelete({})}>
                  <ModalOverlay />
                  <ModalContent>
                      <ModalHeader>Confirm Credit Card deletion</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                          <Text mb={2}>
                              Credit Card to be deleted:  {/* To change */}
                          </Text>
                          <Text>
                              Name: <b>{ccToDelete.cardholderName}</b>
                          </Text>
                          <Text>
                              Last four: <b>{ccToDelete.lastfour}</b>
                          </Text>
                          <Text>
                              Expiry: <b>{ccToDelete.expiryMonth}/{ccToDelete.expiryYear}</b>
                          </Text>
                      </ModalBody>
                      <ModalFooter>
                          <Button
                              variant="outline"
                              mr={3}
                              onClick={() => setCardToDelete({})}
                              data-testid="cc-delete-cancel"
                          >
                              Cancel
                          </Button>
                          <Button
                              isDisabled={deletingCCLoading.loading}
                              isLoading={deletingCCLoading.loading}
                              variant="solid"
                              variantColor="blue"
                              onClick={() => handleDeleteCC()}
                              data-testid="cc-delete-confirm"
                          >
                              Confirm Deletion
                          </Button>
                      </ModalFooter>
                  </ModalContent>
              </Modal>
          )}
          
                                   {pagination.showPagination && <Pager pagination={pagination} />}
      </Container>
  );
});

