import React from "react";
import { Field, FieldProps } from "formik";
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/core";
import { Select } from "..";

interface Props<InitialFormValues> extends FormControlProps {
  name: Extract<keyof InitialFormValues, string>;
  isRequired?: boolean;
  label?: string;
  placeholder?: string;
  inputTestId?: string;
  children?: ({
    field,
    form,
  }: Pick<
    FieldProps<any, InitialFormValues>,
    "field" | "form"
  >) => React.ReactNode;
}

export function FormikField<InitialFormValues>({
  isRequired,
  label,
  name,
  placeholder,
  children,
  inputTestId,
  ...rest
}: Props<InitialFormValues>) {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<any, InitialFormValues>) => (
        <FormControl
          isRequired={isRequired}
          isInvalid={!!form.errors[name] && !!form.touched[name]}
          {...rest}
        >
          {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
          {children ? (
            children({ field, form })
          ) : (
            <Input
              {...field}
              id={name}
              placeholder={placeholder}
              data-testid={inputTestId}
            />
          )}
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
}

interface FormikSelectProps<InitialFormValues>
  extends Omit<FormControlProps, "onChange"> {
  name: Extract<keyof InitialFormValues, string>;
  isRequired?: boolean;
  label?: string;
  testId?: string;
  options: { value: string | number; label: string | number }[];
  onChange?: (value: string) => void;
}

export function FormikSelect<InitialFormValues>({
  name,
  label,
  options,
  isRequired,
  id,
  onChange,
  testId,
  ...rest
}: FormikSelectProps<InitialFormValues>) {
  return (
    <FormikField name={name} label={label} isRequired={isRequired} {...rest}>
      {({ field, form }) => (
        <Select
          id={id}
          options={options}
          defaultValue={options.find((opt) => opt.value === field.value)}
          name={field.name}
          data-testid={testId}
          onChange={({ value }: { value: string }) => {
            form.setFieldValue(field.name, value);
            if (onChange) onChange(value);
          }}
        />
      )}
    </FormikField>
  );
}
