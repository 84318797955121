import React, { forwardRef, useState, useContext, useCallback } from "react";
import classnames from "classnames";
import {
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Stack,
  Button,
  Heading,
  Alert,
  Link as CLink,
} from "@chakra-ui/core";
import { Formik } from "formik";
import logo from "../../logo.svg";
import { history } from "../../lib/history";
import { useLoading } from "../../hooks/useLoading";
import { login } from "../../lib/api/user";
import { ValidationError } from "../../lib/api/base";
import {
  UserFlowPage,
  UserFlowContainer,
  Logo,
  Link,
  UserFlowForm,
} from "../shared";
import { UserContext } from "../../contexts/UserContext";
import { FormikField } from "../shared/forms";
import { loginSchema } from "./schema";

export interface LoginProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

interface IInitialFormValues {
  email: string;
  password: string;
}

const initialFormValues: IInitialFormValues = {
  email: "",
  password: "",
};

export const Login = forwardRef<HTMLDivElement, LoginProps>(
  ({ children, className, ...props }, ref) => {
    const { load } = useContext(UserContext);
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const [loading, , withLoading] = useLoading<void>();

    const onSubmit = useCallback(
      (values: IInitialFormValues) => {
        withLoading(async () => {
          try {
            await login({
              ...values,
              email: values.email.toLocaleLowerCase(),
            });
            await load();
            history.push("/dashboard");
          } catch (e) {
            if (e instanceof ValidationError) {
              if (e.message === "User not verified") {
                history.push(`/otp?email=${encodeURIComponent(values.email)}`);
              } else {
                setSubmitError({ message: e.message });
              }
            } else {
              setSubmitError({
                message: "An unexpected error has occurred",
              });
            }
          }
        });
      },
      [load, withLoading]
    );

    return (
      <UserFlowPage
        {...props}
        ref={ref}
        className={classnames("", {}, className)}
      >
        <UserFlowContainer rounded="lg">
          <Formik
            initialValues={initialFormValues}
            validationSchema={loginSchema}
            onSubmit={onSubmit}
          >
            <UserFlowForm data-testid="login-form">
              <Stack spacing={4}>
                <Logo src={logo} />
                <Heading size="lg">BigStorage Login</Heading>
                {submitError.message && (
                  <Alert status="error">{submitError.message}</Alert>
                )}
                <FormikField<IInitialFormValues> name="email">
                  {({ field }) => (
                    <InputGroup>
                      <InputLeftElement>
                        <Icon name="email" />
                      </InputLeftElement>
                      <Input
                        {...field}
                        data-testid="email"
                        placeholder="email"
                      />
                    </InputGroup>
                  )}
                </FormikField>
                <FormikField<IInitialFormValues> name="password">
                  {({ field }) => (
                    <InputGroup>
                      <InputLeftElement>
                        <Icon name="lock" />
                      </InputLeftElement>
                      <Input
                        {...field}
                        type="password"
                        data-testid="password"
                        placeholder="password"
                      />
                    </InputGroup>
                  )}
                </FormikField>
                <Button
                  data-testid="submit"
                  type="submit"
                  isLoading={loading.loading}
                  variantColor="yellow"
                  loadingText="Submitting"
                >
                  Login
                </Button>
                <span>
                  By logging in to this site, you accept the terms as set out in
                  the{" "}
                  <CLink
                    isExternal
                    color="blue.400"
                    href="https://support.cloudafrica.net/support/solutions/articles/101000439117-terms-of-use"
                    target="_blank"
                  >
                    Terms Of Use Policy
                  </CLink>
                </span>
                <span>
                  New to us <Link to="/register">register here</Link>
                </span>
                <span>
                  <Link to="/reset-request">forgot password?</Link>
                </span>
              </Stack>
            </UserFlowForm>
          </Formik>
        </UserFlowContainer>
      </UserFlowPage>
    );
  }
);
