import { useState, useEffect, useCallback } from "react";
import { useLoading, Loading } from "./useLoading";

export const useApiCall = <T>(
  work: () => Promise<T>,
  initalState: Partial<T>,
  deps: any[]
): [T, Loading, () => Promise<void>] => {
  const [data, setData] = useState<Partial<T>>(initalState);
  const [loading, , withLoading] = useLoading();

  const reload = useCallback(async () => {
    withLoading(async () => {
      const data = await work();
      setData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return [data as T, loading, reload];
};
