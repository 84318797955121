import React from "react";
import { NotAuthenticatedError } from "./lib/api/base";
import { Alert } from "@chakra-ui/core";
import { Redirect } from "react-router";

export class ErrorBoundary extends React.Component<{}> {
  state = {
    hasError: false,
    notAuthenticated: false
  };

  static getDerivedStateFromError(error: any) {
    if (error instanceof NotAuthenticatedError) {
      return { notAuthenticated: true };
    }
    return { hasError: true };
  }

  render() {
    if (this.state.notAuthenticated) {
      return <Redirect to="/login" />;
    }

    if (this.state.hasError) {
      return (
        <Alert>
          Something has gone terribly wrong, please refresh this page to
          continue.
        </Alert>
      );
    }

    return this.props.children;
  }
}
