import * as yup from "yup";
import {
  addressValidators,
  NoLeadingOrTrailingSpaces,
  ValidEmail,
} from "../../lib/schemata";

export const profileFormSchema = yup.object().shape({
  firstName: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
  lastName: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
  secondaryEmail: yup.string().test(ValidEmail).test(NoLeadingOrTrailingSpaces),
  billingEmail: yup.string().test(ValidEmail).test(NoLeadingOrTrailingSpaces),
  technicalEmail: yup.string().test(ValidEmail).test(NoLeadingOrTrailingSpaces),
  ...addressValidators,
});

export const passwordResetSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .test(NoLeadingOrTrailingSpaces)
    .min(8, "Password needs to be at least 8 characters")
    .matches(/[a-z]/, "Password needs at least 1 lowercase letter")
    .matches(/[A-Z]/, "Password needs at least 1 uppercase letter")
    .matches(/[0-9]/, "Password needs at least 1 numeric character"),
  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});
