import * as yup from "yup";
import {
  NoLeadingOrTrailingSpaces,
} from "../../../lib/schemata";

const getStartedSchema = yup.object().shape({
  tagName: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
  tier: yup.string().required("Required"),
});


const existingCardSchema = yup.object().shape({
  cc_existingCard: yup
    .string()
    .when("tier", { is: "paid", then: yup.string().required("Required") }),
});

const emptySchema = yup.object().shape({});

export function getSchemaForStep(
  step: number,
  paymentMethod: string
): yup.ObjectSchema<object | undefined> {
  switch (step) {
    case 1:
      // Name and Tier
      return getStartedSchema;
    case 2:
      // Payment
      return paymentMethod === ":bank-transfer"
        ? emptySchema
        : existingCardSchema;
    case 3:
      // Confirmation
      return getStartedSchema.concat(
        getSchemaForStep(
          2,
          paymentMethod
        ) as yup.ObjectSchema<object>
      );
    default:
      return emptySchema;
  }
}
