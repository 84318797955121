import React, { forwardRef, useCallback, useState } from "react";
import classnames from "classnames";
import {
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Stack,
  Button,
  Heading,
  Alert,
  useToast,
} from "@chakra-ui/core";
import { Formik } from "formik";
import logo from "../../logo.svg";
import { history } from "../../lib/history";
import { useLoading } from "../../hooks/useLoading";
import { confirmResetRequest } from "../../lib/api/user";
import { ValidationError } from "../../lib/api/base";
import { useQueryParam, StringParam } from "use-query-params";
import { UserFlowPage, UserFlowContainer, Logo, UserFlowForm } from "../shared";
import { FormikField } from "../shared/forms";
import { passwordResetSchema } from "../profile/schema";

export interface PasswordResetProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const PasswordReset = forwardRef<HTMLDivElement, PasswordResetProps>(
  ({ children, className, ...props }, ref) => {
    const [token] = useQueryParam("token", StringParam);
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const toast = useToast();
    const [loading, , withLoading] = useLoading<void>();

    const handleSubmit = useCallback(
      (values) => {
        withLoading(async () => {
          try {
            setSubmitError({});
            await confirmResetRequest({ ...values, token: token || "" });
            toast({
              title: "Password reset.",
              description:
                "Your password has successfully been reset, please log in",
              status: "success",
              duration: 2000,
              position: "top",
              isClosable: true,
            });
            history.push("/login");
          } catch (e) {
            if (e instanceof ValidationError) {
              return setSubmitError({ message: e.message });
            }
            return setSubmitError({
              message: "An unexpected error has occurred",
            });
          }
        });
      },
      [toast, token, withLoading]
    );

    return (
      <UserFlowPage
        {...props}
        ref={ref}
        className={classnames("", {}, className)}
      >
        <UserFlowContainer rounded="lg">
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            onSubmit={handleSubmit}
            validationSchema={passwordResetSchema}
          >
            <UserFlowForm>
              <Stack spacing={4}>
                <Logo src={logo} />
                <Heading size="lg">Password Reset</Heading>
                {submitError.message && (
                  <Alert status="error">{submitError.message}</Alert>
                )}
                <FormikField name="password" isRequired>
                  {({ field }) => (
                    <InputGroup>
                      <InputLeftElement>
                        <Icon name="lock" />
                      </InputLeftElement>
                      <Input
                        {...field}
                        type="password"
                        placeholder="Password"
                      />
                    </InputGroup>
                  )}
                </FormikField>
                <FormikField name="confirmPassword" isRequired>
                  {({ field }) => (
                    <InputGroup>
                      <InputLeftElement>
                        <Icon name="lock" />
                      </InputLeftElement>
                      <Input
                        {...field}
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </InputGroup>
                  )}
                </FormikField>
                <br />
                <Button
                  type="submit"
                  isLoading={loading.loading}
                  variantColor="yellow"
                >
                  Reset password
                </Button>
              </Stack>
            </UserFlowForm>
          </Formik>
        </UserFlowContainer>
      </UserFlowPage>
    );
  }
);
