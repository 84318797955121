import { useCallback, useMemo } from "react";

export const useSelectOptions = (
  optionsFactory: () => { value: string | number; label: string | number }[],
  dependencies: unknown[] = []
) => {
  const options = useMemo(optionsFactory, dependencies);

  const all = useCallback(() => options, [options]);
  const one = useCallback(
    (lookup: unknown) => options.find((o) => o.value === lookup),
    [options]
  );

  return { all, one };
};
