import React, {
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  FC,
} from "react";
import classnames from "classnames";
import styled from "@emotion/styled";
import {
  Box,
  Heading,
  Icon,
  Alert,
  AlertIcon,
  Input,
  Stack,
  Grid,
  useToast,
  InputGroup,
  InputLeftElement,
  ButtonGroup,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  Text,
} from "@chakra-ui/core";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { theme } from "../../theme";
import { Loader, Link, LinkButton } from "../shared";
import { account as apiAccount, Account } from "../../lib/api/billing";
import { ValidationError, NotAuthenticatedError } from "../../lib/api/base";
import {
  buckets as apiBuckets,
  Bucket as TBucket,
  deleteBucket,
  deleteBucketConfirmed,
} from "../../lib/api/buckets";
import { useLoading } from "../../hooks/useLoading";
import { OrderWizard } from "../order-wizard";
import { useApiCall } from "../../hooks/useApiCall";
import { history } from "../../lib/history";
import { Redirect } from "react-router";
import { FormikField } from "../shared/forms";
import { NoLeadingOrTrailingSpaces } from "../../lib/schemata";

export const schema = yup.object().shape({
  otp: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
});

export interface DashboardProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const BucketStyled = styled(Box)`
  box-shadow: ${theme.shadows.md};
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[50]};
  padding: 1em;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.gray[50]};
  }
  &:active {
    background-color: ${theme.colors.gray[100]};
  }
  svg {
    &:hover {
      color: ${theme.colors.blue[500]};
    }
  }
`;

type BucketProps = {
  bucket: TBucket;
  onClick: (e: any) => void;
  index: number;
};

const Bucket: FC<BucketProps> = ({ bucket, index, onClick }) => {
  const { usage, denomination } = useMemo(() => {
    return {
      usage: (+bucket.usage > 1024000
        ? +bucket.usage / 1024000
        : +bucket.usage / 1024
      ).toFixed(2),
      denomination: +bucket.usage > 1024000 ? "GB" : "MB",
    };
  }, [bucket]);

  return (
    <BucketStyled data-testid={`bucket-${index}`}>
      <Stack
        onClick={() => {
          history.push(`/buckets/${bucket.id}`);
        }}
      >
        <Heading as="h3" size="md">
          {bucket.tag}
        </Heading>
        <Heading as="h5" size="sm" color={theme.colors.gray[400]}>
          {bucket.name}
        </Heading>
        <span>{bucket.keys.map((key) => key.name).join(", ")}</span>
        <span>
          {usage}
          {denomination}
        </span>
      </Stack>
      <Stack alignItems="flex-end">
        <Icon
          data-testid={`bucket-delete-${index}`}
          onClick={onClick}
          name="delete"
          focusable={true}
        />
      </Stack>
    </BucketStyled>
  );
};

export const AddBucket = styled(BucketStyled)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: start;

  :disabled {
    cursor: not-allowed;
    &:hover {
      background-color: ${theme.colors.white};
    }
  }

  @media (min-width: ${theme.breakpoints[0]}) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

const BucketGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-gap: 2em;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: ${theme.breakpoints[3]}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const Container = styled.div`
  border-radius: ${theme.radii.lg};
`;

export const SearchContainer = styled.div`
  margin-bottom: 1em;
`;

const HowDoI: any = styled(AddBucket)`
  color: #333;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const CardIcon = styled(Icon)`
  width: 2em;
  height: 2em;
  margin-right: 1em;

  @media (min-width: ${theme.breakpoints[0]}) {
    width: 3em;
    height: 3em;
    margin-right: 0;
    margin-bottom: 1em;
  }
`;

export const Dashboard = forwardRef<HTMLDivElement, DashboardProps>(
  ({ children, className, ...props }, ref) => {
    const [loading, , withLoading] = useLoading();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [addLoading] = useLoading();
    const [account, setAccount] = useState<Partial<Account>>({});
    const [search, setSearch] = useState("");
    const toast = useToast();
    const [removeBucketConfirm, setRemoveBucketConfirm] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [bucketDeleteLoading, , withBucketDeleteLoading] = useLoading();
    const [submitError, setSubmitError] = useState<{ message?: string }>({});

    const [buckets, bucketsLoading, reload] = useApiCall<TBucket[]>(
      () => apiBuckets(),
      [],
      []
    );
    const loadAccount = useCallback(
      () =>
        withLoading(async () => {
          if (!loading.loading && !loading.loaded) {
            const acc = await apiAccount();
            setAccount(acc.account);
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const handleSubmit = useCallback(
      (values, { setFieldError }) => {
        withBucketDeleteLoading(async () => {
          try {
            setSubmitError({});
            await deleteBucketConfirmed({
              bucket: removeBucketConfirm,
              otp: values.otp,
            });
            await reload();

            onClose();
            setOtpSent(false);
            setRemoveBucketConfirm("");

            toast({
              title: "Bucket successfully deleted",
              status: "success",
              duration: 3000,
              position: "top",
            });
          } catch (e) {
            if (e instanceof ValidationError) {
              if (e.message === "Invalid otp") {
                setFieldError("otp", e.message);
              } else {
                setSubmitError({ message: e.message });
              }
            } else {
              if (e instanceof NotAuthenticatedError) {
                toast({
                  title: "Session expired",
                  status: "error",
                  duration: 3000,
                  position: "top",
                });
                return history.push("/login");
              }
              setSubmitError({
                message: "An unexpected error has occurred",
              });
            }
          }
        });
      },
      [onClose, reload, removeBucketConfirm, toast, withBucketDeleteLoading]
    );

    useEffect(() => {
      loadAccount();
    }, [loadAccount]);

    const filteredBuckets = useMemo(() => {
      return buckets.filter((bucket) =>
        !!search
          ? `${bucket.name}${bucket.tag}`
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          : true
      );
    }, [buckets, search]);

    if (loading.error && loading.error instanceof NotAuthenticatedError) {
      return <Redirect to="/login" />;
    }

    if (loading.loading || bucketsLoading.loading) {
      return <Loader>fetching account...</Loader>;
    }

    if (!account.tier) {
      return <OrderWizard />;
    }

    const bucketLimitReached = account.tier === "free" && buckets.length === 5;

    return (
      <Container {...props} ref={ref} className={classnames("", {}, className)}>
        {account.tier === "free" && (
          <>
            <Alert marginBottom="1em">
              <AlertIcon name="info" />
              <p>
                You are on the free tier, you can upgrade your account by
                filling in your credit card details&nbsp;
                <Link to="/billing/payment-details" data-testid="teir-message">
                  here
                </Link>
                .
              </p>
            </Alert>
          </>
        )}
        {bucketLimitReached && (
          <>
            <Alert marginBottom="1em">
              <AlertIcon name="info" />
              <p>
                You have reached your free tier limit of 5 buckets, you can
                upgrade your account by filling in your credit card
                details&nbsp;
                <Link
                  to="/billing/payment-details"
                  data-testid="teir-message-limit"
                >
                  here
                </Link>
                .
              </p>
            </Alert>
          </>
        )}
        {buckets.length !== 0 && (
          <SearchContainer>
            <Input
              placeholder="Search..."
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
          </SearchContainer>
        )}
        <BucketGrid>
          {filteredBuckets.map((bucket, i) => (
            <Bucket
              index={i}
              key={bucket.id}
              bucket={bucket}
              onClick={(e) => {
                e.preventDefault();
                setRemoveBucketConfirm(bucket.id);
                setOtpSent(false);
                onOpen();
              }}
            />
          ))}
          <AddBucket
            data-testid="add-bucket"
            as="button"
            disabled={bucketLimitReached}
            onClick={async () => {
              if (bucketLimitReached) {
                return null;
              }
              history.push("/orders/new");
            }}
          >
            {addLoading.loading ? (
              <Loader />
            ) : (
              <>
                <CardIcon
                  color={bucketLimitReached ? "gray.400" : "#21BD89"}
                  name="add"
                />
                <Heading
                  as="h3"
                  size="md"
                  color={bucketLimitReached ? "gray.400" : "inherit"}
                >
                  Add a new bucket
                </Heading>
              </>
            )}
          </AddBucket>
          <HowDoI
            data-testid="mount-bucket"
            as={Link}
            to="/how-do-i-access-my-bucket"
          >
            <CardIcon name="question" color="blue.500" />
            <Heading as="h3" size="md" color={"inherit"}>
              How do I access my bucket
            </Heading>
          </HowDoI>
        </BucketGrid>
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader paddingBottom="0" alignSelf="center">
              <Heading textAlign="center" as="h3" size="lg">
                Delete Bucket
              </Heading>
              <Heading textAlign="center" as="h4" size="md">
                Step {otpSent ? "2" : "1"}
              </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <Divider />
            <ModalBody>
              {submitError.message && (
                <Alert status="error" marginBottom="0.5em">
                  {submitError.message}
                </Alert>
              )}{" "}
              {otpSent ? (
                <Formik
                  initialValues={{ otp: "" }}
                  onSubmit={handleSubmit}
                  validationSchema={schema}
                >
                  <Form>
                    <FormikField name="otp" label="OTP Confirmation" isRequired>
                      {({ field }) => (
                        <InputGroup>
                          <InputLeftElement>
                            <Icon name="lock" />
                          </InputLeftElement>
                          <Input {...field} placeholder="OTP" />
                        </InputGroup>
                      )}
                    </FormikField>
                    <LinkButton
                      type="button"
                      onClick={async () => {
                        await deleteBucket({
                          bucket: removeBucketConfirm,
                        });
                        toast({
                          title: "OTP Resent",
                          status: "success",
                          duration: 3000,
                          position: "top",
                        });
                      }}
                    >
                      Resend OTP
                    </LinkButton>
                    <Stack marginTop="1em">
                      <ButtonGroup alignSelf="flex-end" spacing={2}>
                        <Button
                          variant="outline"
                          size="sm"
                          isDisabled={bucketDeleteLoading.loading}
                          variantColor="blue"
                          onClick={() => {
                            setOtpSent(false);
                            setRemoveBucketConfirm("");
                            onClose();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          isLoading={bucketDeleteLoading.loading}
                          isDisabled={bucketDeleteLoading.loading}
                          variant="solid"
                          size="sm"
                          variantColor="blue"
                        >
                          Delete Bucket
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  </Form>
                </Formik>
              ) : (
                <Grid templateRows="auto auto auto" gap={6}>
                  <Stack>
                    <Text as="b">
                      You are about to delete the Storage Bucket{" "}
                      <span style={{ color: theme.colors.gray[500] }}>
                        {
                          (
                            buckets.find(
                              (bucket) => bucket.id === removeBucketConfirm
                            ) || {}
                          ).name
                        }
                      </span>{" "}
                      as well as all the folders, sub folders and content. All
                      users who have been granted access will lose their access
                      immediately.
                    </Text>
                  </Stack>
                  <Stack>
                    <Text as="b" color={theme.colors.red[500]}>
                      You cannot undo this action!!!
                    </Text>
                  </Stack>
                  <Stack>
                    <Text as="b">
                      Choose "Continue" to generate a One Time Pin and proceed.
                      <p style={{ color: theme.colors.gray[500] }}>
                        NOTE: Your OTP is only valid for 2 Hours, after which it
                        will expire.
                      </p>
                    </Text>
                  </Stack>
                </Grid>
              )}
            </ModalBody>
            <ModalFooter>
              {!otpSent && (
                <>
                  <Button
                    variant="outline"
                    size="sm"
                    variantColor="blue"
                    data-test="cancel-bucket-delete"
                    mr={3}
                    onClick={() => {
                      setOtpSent(false);
                      setRemoveBucketConfirm("");
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="solid"
                    size="sm"
                    variantColor="blue"
                    isLoading={bucketDeleteLoading.loading}
                    isDisabled={bucketDeleteLoading.loading}
                    data-test="continue-bucket-delete"
                    onClick={async () => {
                      withBucketDeleteLoading(async () => {
                        setSubmitError({});
                        try {
                          await deleteBucket({
                            bucket: removeBucketConfirm,
                          });
                          setOtpSent(true);
                        } catch (e) {
                          if (e instanceof NotAuthenticatedError) {
                            toast({
                              title: "Session expired",
                              status: "error",
                              duration: 3000,
                              position: "top",
                            });
                            return history.push("/login");
                          }
                          setSubmitError({ message: e.message });
                        }
                      });
                    }}
                  >
                    Continue
                  </Button>
                </>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
    );
  }
);
