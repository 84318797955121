import React, { forwardRef, useState, useMemo, useEffect } from "react";
import classnames from "classnames";
import { useApiCall } from "../../../hooks/useApiCall";
import { usePagination } from "../../../hooks/usePagination";
import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { Badge, Switch, Input, FormLabel, Grid } from "@chakra-ui/core";
import { Loader } from "../../shared";
import { Pager } from "../../shared/Pager";
import { useQueryParams, NumberParam } from "use-query-params";
import { orders } from "../../../lib/api/orders";

export interface OrdersProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  margin-top: 1em;
`;

const Row = styled.div`
  box-shadow: ${theme.shadows.md};
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[50]};
  padding: 1em;
  cursor: pointer;
  display: grid;
  background-color: #fff;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
  justify-content: space-between;
  h3 {
    margin-bottom: 0.25em;
  }
  &:hover {
    background-color: #fafafa;
  }
  &:active {
    background-color: #f8f8f8;
  }

  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 1fr 2fr 2fr 2fr auto;
  }
`;

const Column = styled.div<{ textAlign: "left" | "right" }>`
  display: flex;
  flex-direction: column;
  &:last-child {
    text-align: ${(props) => props.textAlign};
  }
  span:first-child {
    font-weight: bold;
    color: ${theme.colors.gray[500]};
  }
`;

const perPage = 10;

export const Orders = forwardRef<HTMLDivElement, OrdersProps>(
  ({ children, className, ...props }, ref) => {
    const [userOrders, loading] = useApiCall(() => orders(), [], []);
    const [search, setSearch] = useState("");
    const [showAllFilter, setShowAll] = useState<boolean>(false);

    const [paginator, setPaginator] = useQueryParams({
      page: NumberParam,
      perPage: NumberParam,
    });

    useEffect(() => {
      setPaginator({ page: 1 });
    }, [showAllFilter, setPaginator]);

    const filteredOrders = useMemo(() => {
      return userOrders
        .filter((order) => showAllFilter || order.status === ":active")
        .filter((order) =>
          !!search
            ? `${order.account}${order.paymentFreq}${order.product}${order.resource}`
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
            : true
        )
        .sort((a, b) => {
          return +b.createdAt - +a.createdAt;
        });
    }, [showAllFilter, userOrders, search]);

    const pagination = usePagination({
      count: filteredOrders.length,
      page: paginator.page || 1,
      rowsPerPage: paginator.perPage || perPage,
      rowsPerPageOptions: [10, 30, 50],
    });

    if (loading.loading) {
      return <Loader>fetching orders...</Loader>;
    }
    if (loading.loaded && userOrders.length === 0) {
      return (
        <Container
          {...props}
          ref={ref}
          className={classnames("", {}, className)}
        >
          <Row>You don't have any orders yet</Row>
        </Container>
      );
    }

    return (
      <Container {...props} ref={ref} className={classnames("", {}, className)}>
        <div>
          <Grid alignItems={"center"} templateColumns="auto 1fr" gap={4}>
            <div>
              <FormLabel htmlFor="email-alerts">Show All</FormLabel>
              <Switch
                data-testid="show-all"
                onChange={() => setShowAll(!showAllFilter)}
                isChecked={showAllFilter}
              />
            </div>
            <div>
              <Input
                data-testid="search"
                placeholder="Search..."
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
              />
            </div>
          </Grid>
        </div>
        {filteredOrders
          .slice(pagination.begin, pagination.end)
          .map((order, i) => (
            <Row key={i} data-testid={`order-${i}`}>
              <Column textAlign="left">
                <span>Order Date</span>
                <span>{order.createdAt.toLocaleDateString()}</span>
              </Column>
              <Column textAlign="left">
                <span>Product</span>
                <span>{order.product}</span>
              </Column>
              <Column textAlign="left">
                <span>Resource</span>
                <span>{order.resource}</span>
              </Column>
              <Column textAlign="left">
                <span>Payment Frequency</span>
                <span>{order.paymentFreq}</span>
              </Column>
              <Column textAlign="left">
                <span>Status</span>
                <span>
                  <Badge>{order.status.replace(":", "")}</Badge>
                </span>
              </Column>
            </Row>
          ))}
        {pagination.showPagination && <Pager pagination={pagination} />}
      </Container>
    );
  }
);
