import React, { forwardRef } from "react";
import classnames from "classnames";
import { Tabs, TabPanels, TabPanel } from "@chakra-ui/core";
import { Route, useRouteMatch } from "react-router";
import { Orders } from "./orders";
import { ManageAccount } from "./accounts/ManageAccount";
import { history } from "../../lib/history";
import { ManagePaymentDetails } from "./accounts/ManagePaymentDetails";
import { ManageCreditCards } from "./accounts/ManageCreditCards";
import { Tab, TabList } from "../shared";

export interface BilllngProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const tabIndicies = {
  account: 0,
  orders: 1,
    "payment-details": 2,
    "credit-cards": 3,
};

export const Billlng = forwardRef<HTMLDivElement, BilllngProps>(
  ({ children, className, ...props }, ref) => {
    const match = useRouteMatch<{
      tab: "payment-details" | "orders" | "account" | "credit-cards";
    }>("/billing/:tab");
    return (
      <div {...props} ref={ref} className={classnames("", {}, className)}>
        <Tabs isFitted index={tabIndicies[match?.params.tab || "orders"]}>
          <TabList>
            <Tab
              data-testid="account-page"
              onClick={() => history.push("/billing/account")}
            >
              Account
            </Tab>
            <Tab
              data-testid="orders-page"
              onClick={() => history.push("/billing/orders")}
            >
              Orders
            </Tab>
            <Tab
              data-testid="cards-page"
              onClick={() => history.push("/billing/payment-details")}
            >
              Payment details
            </Tab>
            <Tab
              data-testid="cards-page"
              onClick={() => history.push("/billing/credit-cards")}
            >
              Credit Cards
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Route path="/billing/account" render={() => <ManageAccount />} />
            </TabPanel>
            <TabPanel>
              <Route path="/billing/orders" render={() => <Orders />} />
            </TabPanel>
            <TabPanel>
              <Route
                path="/billing/payment-details"
                render={() => <ManagePaymentDetails />}
              />
            </TabPanel>
            <TabPanel>
              <Route
                  exact path="/billing/credit-cards"
                  render={() => <ManageCreditCards />}
              />
              <Route
                  exact path="/billing/credit-cards/peach-3ds/result"
                  render={() => <ManageCreditCards />}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    );
  }
);
