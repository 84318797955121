import React from "react";
import { Stack } from "@chakra-ui/core";
import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { AccountTier } from "../../../lib/api/billing";

export interface NewBucketWizardProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const StepContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "get-started"
    "payment"
    "confirm"
    "complete";
  grid-gap: 0.5em;
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "get-started payment confirm complete";
  }
  margin-bottom: 1.25em;
`;

const StyledStepIndicator = styled.div<{
  active?: boolean;
  clickable?: boolean;
}>`
  width: 100%;
  height: 100%;
  color: ${(props) => (props.active ? "#fff" : "#000")};
  background-color: ${(props) =>
    props.active ? theme.colors.blue[500] : "#FFF"};
  text-align: center;
  box-shadow: ${theme.shadows.md};
  border-radius: ${theme.radii.lg};
  cursor: ${(props) => (props.clickable ? "pointer" : "not-allowed")};
  grid-gap: 1em;
  padding: 0.25em;

  @media (min-width: ${theme.breakpoints[0]}) {
    padding: 0.5em;
  }
`;

const StepIndicator = ({
  children,
  onClick,
  clickable,
  active,
}: {
  children: React.ReactNode;
  clickable: boolean;
  onClick: () => void;
  active: boolean;
}) => {
  return (
    <StyledStepIndicator
      onClick={() => clickable && onClick()}
      clickable={clickable}
      active={active}
    >
      {children}
    </StyledStepIndicator>
  );
};

export const Steps = ({
  step,
  reachedStep,
  setStep,
  tier,
}: {
  step: number;
  reachedStep: number;
  setStep: (step: number) => void;
  tier: AccountTier;
}) => {
  const isClickable = (step: number) => {
    return reachedStep >= step && !(step === 2 && tier === "free");
  };
  return (
    <StepContainer>
      <Stack gridArea="get-started" align="center">
        <StepIndicator
          clickable={isClickable(1)}
          onClick={() => setStep(1)}
          active={step === 1}
        >
          Get Started
        </StepIndicator>
      </Stack>
      <Stack gridArea="payment" align="center">
        <StepIndicator
          clickable={isClickable(2)}
          onClick={() => setStep(2)}
          active={step === 2}
        >
          Payment
        </StepIndicator>
      </Stack>
      <Stack gridArea="confirm" align="center">
        <StepIndicator
          clickable={isClickable(3)}
          onClick={() => setStep(3)}
          active={step === 3}
        >
          Confirm Order
        </StepIndicator>
      </Stack>
      <Stack gridArea="complete" align="center">
        <StepIndicator
          clickable={isClickable(4)}
          onClick={() => setStep(4)}
          active={step === 4}
        >
          Complete
        </StepIndicator>
      </Stack>
    </StepContainer>
  );
};
