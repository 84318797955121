import React, { useMemo, useState } from "react";
import {
  Code as CCode,
  FormControl,
  FormLabel,
  Heading,
  Link,
  Select,
} from "@chakra-ui/core";
import styled from "@emotion/styled";
import { useApiCall } from "../../hooks/useApiCall";
import {
  getKeys,
  buckets as apiBuckets,
  Bucket as TBucket,
} from "../../lib/api/buckets";
import { theme } from "../../theme";
import { Section } from "../profile/Shared";
import cyberduck2 from "./mounting/windows/cyberduck/step-2.png";
import s3browser1 from "./mounting/windows/s3browser/step-1.png";
import s3browser2 from "./mounting/windows/s3browser/step-2.png";
import s3browser3 from "./mounting/windows/s3browser/step-3.png";
import osxcyberduck1 from "./mounting/osx/cyberduck/step-1.png";
import osxcyberduck2 from "./mounting/osx/cyberduck/step-2.png";
import transmit5 from "./mounting/osx/transmit5/step-1.png";

const Card = styled.div`
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[100]};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.md};
  padding: 1em;
  margin-top: 1em;
  word-break: break-all;
`;

const Code = styled(CCode)`
  word-break: break-all;
`;

const Img = styled.img`
  margin-top: 1em;
`;

const Image = ({ src, alt }: { src: string; alt: string }) => (
  <Link href={src} target="_blank">
    <Img src={src} alt={alt} />
  </Link>
);

export const MountingWizard = () => {
  const [os, setOs] = useState<string | undefined>("Ubuntu/Debian");
  const [method, setMethod] = useState<string | undefined>("s3fs");
  const [key, setKey] = useState<string | undefined>();
  const [bucket, setBucket] = useState<string | undefined>();
  const [keys] = useApiCall(
    async () => {
      const keys = await getKeys();
      setKey(keys[0].id);
      return keys;
    },
    [],
    []
  );
  const [buckets] = useApiCall<TBucket[]>(
    async () => {
      const buckets = await apiBuckets();
      setBucket(buckets[0].id);
      return buckets;
    },
    [],
    []
  );

  const selectedKey = useMemo(
    () => (keys.find((k) => k.id === key) || {}) as any,
    [key, keys]
  );

  const selectedBucket = useMemo(
    () => (buckets.find((b) => b.id === bucket) || {}) as any,
    [bucket, buckets]
  );

  return (
    <div>
      <Heading mb="0.5em" size="lg">
        How do I access my bucket
      </Heading>
      <Section>
        <FormControl mt="1em">
          <FormLabel>Please select your operating system</FormLabel>
          <Select
            value={os}
            onChange={(e) => {
              setOs(e.target.value);
              setMethod("");
            }}
          >
            <option></option>
            <option>OSX</option>
            <option>Windows</option>
            <option>Ubuntu/Debian</option>
            <option>CentOS/Fedora</option>
          </Select>
        </FormControl>
        {os && (
          <FormControl mt="1em">
            <FormLabel>
              Please select the method you'd like to use to access your bucket
            </FormLabel>
            <Select
              value={method}
              onChange={(e) => {
                setMethod(e.target.value);
              }}
            >
              <option></option>
              {os === "Windows" && (
                <>
                  <option>Cyberduck</option>
                  <option>s3Browser</option>
                </>
              )}
              {["Ubuntu/Debian", "OSX", "CentOS/Fedora"].includes(os) && (
                <>
                  <option value="s3cmd">
                    s3cmd - I want to access my files from the command line
                  </option>
                  <option value="s3fs">
                    s3fs - I want to mount my bucket as a filesystem
                  </option>
                </>
              )}
              {os === "OSX" && (
                <>
                  <option>Cyberduck</option>
                  <option>transmit5</option>
                </>
              )}
            </Select>
          </FormControl>
        )}
        {os && method && (
          <FormControl mt="1em">
            <FormLabel>Please select the key you'd like to use</FormLabel>
            <Select
              value={key}
              onChange={(e) => {
                setKey(e.target.value);
              }}
            >
              <option></option>
              {keys.map((key) => (
                <option key={key.id} value={key.id}>
                  {key.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        {os && method && key && (
          <FormControl mt="1em">
            <FormLabel>Please select the bucket you'd like to use</FormLabel>
            <Select
              value={bucket}
              onChange={(e) => {
                setBucket(e.target.value);
              }}
            >
              <option></option>
              {buckets
                .filter(
                  (bucket) =>
                    keys.some((k) => k.name === "master" && k.id === key) ||
                    bucket.keys.some((bk) => bk.id === key)
                )
                .map((bucket) => (
                  <option key={bucket.id} value={bucket.id}>
                    {bucket.name} - {bucket.tag}
                  </option>
                ))}
            </Select>
          </FormControl>
        )}
        {["Cyberduck", "transmit5", "s3Browser"].includes(method || "") && (
          <Card>
            <b>Access Key</b>
            <p>{selectedKey.accessKey}</p>
            <b>Secret Key</b>
            <p>{selectedKey.secretKey}</p>
          </Card>
        )}
        {os === "OSX" && method === "Cyberduck" && (
          <>
            <Image src={osxcyberduck1} alt="step-1" />
            <Image src={osxcyberduck2} alt="step-2" />
          </>
        )}

        {os === "OSX" && method === "transmit5" && (
          <>
            <Image src={transmit5} alt="step-1" />
          </>
        )}
        {os === "Windows" && method === "Cyberduck" && (
          <>
            <Image src={cyberduck2} alt="step-1" />
          </>
        )}
        {method === "s3Browser" && (
          <>
            <Image src={s3browser1} alt="step-1" />
            <Image src={s3browser2} alt="step-2" />
            <Image src={s3browser3} alt="step-3" />
          </>
        )}
        {method === "s3cmd" && (
          <>
            <p style={{ marginTop: "1em" }}>Install s3cmd</p>
            <Code p="1em">
              {os === "Ubuntu/Debian" && <p>sudo apt install s3cmd</p>}
              {os === "OSX" && <p>brew install s3cmd</p>}
              {os === "CentOS/Fedora" && <p>sudo dnf install s3cmd</p>}
            </Code>
            <p style={{ marginTop: "1em" }}>
              Create a file ~/.s3cfg with the following contents
            </p>
            <Code p="1em">
              <p>[default] # access keys for ${selectedKey.name}</p>
              <p>access_key = ${selectedKey.accessKey}</p>
              <p>secret_key = ${selectedKey.secretKey}</p>
              <p>host_base = s3.bigstorage.io </p>
              <p>host_bucket = %(bucket)s.s3.bigstorage.io </p>
            </Code>
            <p style={{ marginTop: "1em" }}>
              Then you can create and download files as follows
            </p>
            <Code p="1em">
              <p>touch file-to-upload</p>
              <p>s3cmd put file-to-upload s3://{selectedBucket.name}</p>
              <p>
                s3cmd get s3://{selectedBucket.name}/file-to-upload
                downloaded-file
              </p>
              <p>rm downloaded-file</p>
              <p>s3cmd del s3://{selectedBucket.name}/file-to-upload</p>
            </Code>
          </>
        )}
        {method === "s3fs" && (
          <Code p="1em" mt="1em">
            {os === "Ubuntu/Debian" && <p>sudo apt install s3fs</p>}
            {os === "OSX" && <p>brew install s3fs</p>}
            {os === "CentOS/Fedora" && <p>sudo dnf install s3fs-fuse</p>}
            <p>
              echo {selectedKey.accessKey}:{selectedKey.secretKey} &gt;
              ~/.passwd-s3fs
            </p>
            <p>chmod 600 .passwd-s3fs</p>
            <p>mkdir ~/s3-drive</p>
            <p>
              s3fs {selectedBucket.name} ~/s3-drive -o
              url=https://s3.bigstorage.io
            </p>
            <p>touch ~/s3-drive/test-file</p>
            <p>ls ~/s3-drive</p>
            <p>rm ~/s3-drive/test-file</p>
          </Code>
        )}
      </Section>
    </div>
  );
};
