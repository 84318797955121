import { Text } from "@chakra-ui/core";
import React from "react";
import { history } from "../../../../lib/history";
import { StepControls } from "../StepControls";

export const Complete = ({
  createdBucketName,
}: {
  createdBucketName: string;
}) => (
  <>
    <Text mb="1em">
      Congratulations! Your bucket <b>[{createdBucketName}]</b> has been
      created.
    </Text>
    <Text mb="1em">
      The full domain name is <b>[{createdBucketName}.s3.bigstorage.io]</b>.
    </Text>
    <Text>
      You can now proceed to create keys to manage secure access to your bucket.
    </Text>
    <StepControls
      onCancel={() => history.push("/dashboard")}
      onConfirm={() => history.push("/keys?page=1")}
      cancelText="Dashboard"
      confirmText="Add Keys"
      cancelTestId="dashboard"
      confirmTestId="keys"
      isValid
    />
  </>
);
