export const apiHeaders = (
  content: { length: number } = { length: 0 },
  options: {} = {}
) =>
  new Headers({
    Accept: "application/json",
    "Content-Length": `${content.length}`,
    "Content-Type": "application/json",
    "X-csrf-validation": "-",
    ...options,
  });

export class ApiError extends Error {
  isApiError = true;
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}

export class NotAuthenticatedError extends ApiError {}
export class NotFoundError extends ApiError {}

export class ValidationError extends ApiError {}

export const request = async (path: string, options: RequestInit = {}) => {
  const response = await fetch(
    `${process.env.REACT_GATEWAY_URL || ""}/api/${path}`,
    {
      credentials: "include",
      headers: apiHeaders(
        typeof options.body === "string" ? options.body : undefined
      ),
      ...options,
    }
  );

  if (response.status === 401) {
    const { message } = await response.json();
    throw new NotAuthenticatedError(message, response);
  }

  if (response.status === 404) {
    const { message } = await response.json();
    throw new NotFoundError(message, response);
  }

  if (response.status === 422) {
    const { message } = await response.json();
    throw new ValidationError(message, response);
  }

  if (response.status >= 500 && response.status < 600) {
    const { message } = await response.json();
    throw new ApiError(message, response);
  }

  return response;
};
