import React, {
  forwardRef,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import * as yup from "yup";
import {
    NoLeadingOrTrailingSpaces,
    addressValidators,
    cellValidator,
} from "../../lib/schemata";
import classnames from "classnames";
import {
  Heading,
  Input,
  FormLabel,
  Stack,
  InputLeftAddon,
  Button,
  useToast,
  Box,
  Alert,
  AlertIcon,
  InputGroup,
} from "@chakra-ui/core";
import { useLoading } from "../../hooks/useLoading";
import { Container, Section } from "../profile/Shared";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import { ValidationError, NotAuthenticatedError } from "../../lib/api/base";
import { CreditCard, creditCards } from "../../lib/api/billing";
import { useApiCall } from "../../hooks/useApiCall";
import { placeFirstBucketOrder } from "../../lib/api/orders";
import { UserContext } from "../../contexts/UserContext";
import { history } from "../../lib/history";
import { countries } from "../../lib/countries";
import { Form, Formik, FormikHelpers } from "formik";
import { useFormOptions } from "../order-wizard-new/new-bucket/steps/Payment";
import { Profile } from "../../lib/api/user";
import { FormikField, FormikSelect } from "../shared/forms";

export interface AccountFormProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const SectionContainer = styled(Box)`
  display: grid;
  grid-template-areas:
    "basic"
    "address"
    "credit"
    "button";
  grid-gap: 1em;
  @media (min-width: ${theme.breakpoints[1]}) {
    grid-template-areas:
      "basic address"
      "credit credit"
      "button button";
    grid-template-columns: 1fr 1fr;
  }
`;


const StyledButton = styled(Button)`
  width: 100%;
  padding-left: 2em;
  padding-right: 2em;

  @media (min-width: ${theme.breakpoints[0]}) {
    width: auto;
  }
`;

export const accountFormSchema = yup.object().shape({
        regNumber: yup.string().test(NoLeadingOrTrailingSpaces),
        vatNumber: yup.string().test(NoLeadingOrTrailingSpaces),
        country: yup.string(),
        cell: cellValidator,
        ...addressValidators,
    });

const useInitFormValues = (user: Partial<Profile>, ccs: CreditCard[]) => {
  const defaultCountry = useMemo(
    () =>
      countries.find((c) => c.code === user.country) || {
        code: "ZA",
        dialCode: "+27",
        name: "South Africa",
      },
    [user.country]
  );

  const defaultCell = useMemo(
    () =>
      user.cell ? user.cell.replace(defaultCountry?.dialCode || "", "") : "",
    [defaultCountry, user.cell]
  );

  return {
    regNumber: "",
    vatNumber: "",
    street: "",
    extra: "",
    city: "",
    province: "",
    cell: defaultCell,
    country: defaultCountry.code,
    postalCode: "",
    cc_existingCard: ccs[0]?.id || "",
  } as IInitialFormValues;
};

interface IInitialFormValues {
  regNumber: string;
  vatNumber: string;
  street: string;
  extra: string;
  city: string;
  province: string;
  cell: string;
  country: string;
  postalCode: string;
  cc_existingCard: string;
}

export const AccountForm = forwardRef<HTMLDivElement, AccountFormProps>(
  ({ children, className, ...props }, ref) => {
    const { user } = useContext(UserContext);
    const [loading, , withLoading] = useLoading<any>();
    const [ccs] = useApiCall(() => creditCards(), [], []);
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const toast = useToast();
    const defaultCountry = useMemo(
      () =>
        countries.find((c) => c.code === user.country) || {
          code: "ZA",
          dialCode: "+27",
          name: "South Africa",
        },
      [user]
    );
    const [cellPrefix, setCellPrefix] = useState<string>(
      defaultCountry.dialCode || "+27"
    );
    const options = useFormOptions({ ccs });

    const initFormValues = useInitFormValues(user, ccs);

    const handleSubmit = useCallback(
      (
        values: IInitialFormValues,
        helpers: FormikHelpers<IInitialFormValues>
      ) => {
        withLoading(async () => {
          try {
              setSubmitError({});
              await placeFirstBucketOrder({
                  tier: "paid",
                  cell: `${cellPrefix}${values.cell}`.replace(" ", ""),
                  country: values.country,
                  account: {
                      address: {
                          city: values.city,
                          street: values.street,
                          postalCode: values.postalCode,
                          province: values.province,
                          extra: values.extra,
                      },
                      companyNumber: values.regNumber,
                      vatNumber: values.vatNumber,
                      name: user.email || "",
                  },
                  existingCard: values.cc_existingCard,
                  tag: "First bucket",
              });
              helpers.setSubmitting(false);
              history.push("/dashboard");
              toast({
                  title: "Account created.",
                  description: "Your account has successfully been created",
                  status: "success",
                  duration: 2000,
                  position: "top",
                  isClosable: true,
              });
          } catch (e) {
            helpers.setSubmitting(false);

            if (e instanceof ValidationError) {
              return setSubmitError({ message: e.message });
            }

            if (e instanceof NotAuthenticatedError) {
              toast({
                title: "Session expired",
                status: "error",
                duration: 3000,
                position: "top",
              });
              return history.push("/login");
            }
            return setSubmitError({
              message: "An unexpected error has occurred",
            });
          }
        });
      },
      [cellPrefix, toast, user.email, withLoading]
    );

    return (
      <Container
        {...props}
        withMargins
        ref={ref}
        className={classnames("", {}, className)}
      >
        <Formik
          initialValues={initFormValues}
          onSubmit={handleSubmit}
          validationSchema={accountFormSchema}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <Heading
                as="h3"
                size="lg"
                paddingBottom="0.5em"
                paddingTop="0.5em"
              >
                Create a new account
              </Heading>
              {submitError.message && (
                <Alert status="error" marginBottom="0.5em">
                  {submitError.message}
                </Alert>
              )}
              <SectionContainer>
                <Section gridArea="basic">
                  <Heading as="h3" size="md" paddingBottom="0.25em">
                    Basic information
                  </Heading>
                  <FormikField<IInitialFormValues>
                    label="Company Registration Number"
                    name="regNumber"
                    placeholder="e.g. 3123AS21"
                  />
                  <FormikField<IInitialFormValues>
                    label="Company Vat Number"
                    name="vatNumber"
                    placeholder="e.g. 31233124"
                  />
                  <FormikSelect<IInitialFormValues>
                    name="country"
                    label="Country"
                    testId="country"
                    options={options.country.all()}
                    onChange={(value) => {
                      setCellPrefix(
                        countries.find((c) => c.code === value)?.dialCode || ""
                      );
                    }}
                  />
                  <FormikField<IInitialFormValues>
                    label="Cellphone number"
                    name="cell"
                  >
                    {({ field }) => (
                      <InputGroup>
                        <InputLeftAddon>{cellPrefix}</InputLeftAddon>
                        <Input
                          {...field}
                          name={field.name}
                          placeholder="8889992"
                        />
                      </InputGroup>
                    )}
                  </FormikField>
                </Section>
                <Section gridArea="address">
                  <Heading as="h3" size="md" paddingBottom="0.25em">
                    Address
                  </Heading>
                  <Stack>
                    <FormikField<IInitialFormValues>
                      name="street"
                      label="Street Address"
                      placeholder="e.g. 123 Willoughby way"
                      isRequired
                    />
                    <FormikField<IInitialFormValues>
                      name="extra"
                      label="Extra detail"
                      placeholder="e.g. Apartment 203"
                    />
                    <FormikField<IInitialFormValues>
                      name="city"
                      label="City"
                      placeholder="e.g. Cape Town"
                      isRequired
                    />
                    <FormikField<IInitialFormValues>
                      name="province"
                      label="Province"
                      placeholder="e.g. Western Cape"
                      isRequired
                    />
                    <FormikField<IInitialFormValues>
                      name="postalCode"
                      label="Postal Code"
                      placeholder="e.g. 8001"
                      isRequired
                    />
                  </Stack>
                </Section>
                <Section gridArea="credit">
                  <Heading as="h3" size="md" paddingBottom="0.25em">
                    Credit Card
                    {ccs.length > 0 && (
                      <div>
                        <FormLabel htmlFor="email-alerts">
                          Use existing card
                        </FormLabel>
                      </div>
                    )}
                  </Heading>
                  {ccs.length === 0 && (
                      <Alert status="info" marginBottom="0.5em">
                          <AlertIcon /> 
                          You don't currently have any Credit Cards added. Please go to Billing and then Credit Cards tab in order to add your first Credit Card.
                      </Alert>
                  )}
                  <>
                      <FormikSelect
                        label="Credit card"
                        name="cc_existingCard"
                        options={options.creditCards.all()}
                      />
                  </>
                </Section>
                <Stack gridArea="button" align="flex-end">
                  <StyledButton
                    type="submit"
                    isLoading={loading.loading}
                    variantColor="blue"
                  >
                    Create
                  </StyledButton>
                </Stack>
              </SectionContainer>
            </Form>
          )}
        </Formik>
      </Container>
    );
  }
);
