import React, { forwardRef } from "react";
import classnames from "classnames";
import {} from "@chakra-ui/core";
import { Route, Switch } from "react-router";
import { TierSelection } from "./Selection";
import { FirstOrder } from "./FirstOrder";

export interface OrderWizardProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const OrderWizard = forwardRef<HTMLDivElement, OrderWizardProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div {...props} ref={ref} className={classnames("", {}, className)}>
        <Switch>
          <Route path="/orders/first" component={FirstOrder} />
          <Route render={() => <TierSelection />} />
        </Switch>
      </div>
    );
  }
);
