import React, {
  createContext,
  useState,
  FC,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { Profile, whoami } from "../lib/api/user";
import { Loading, useLoading } from "../hooks/useLoading";
import { NotAuthenticatedError } from "../lib/api/base";
import { Redirect } from "react-router-dom";
import { useToast } from "@chakra-ui/core";

export type UserContextState = {
  user: Partial<Profile>;
  loading: Loading;
  load: () => Promise<void>;
  profileComplete: boolean;
};

export const UserContext = createContext<UserContextState>({
  user: {},
  loading: {
    loading: false,
    loaded: false,
    error: undefined,
  },
  profileComplete: false,
  load: () => Promise.resolve(),
});

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<Partial<Profile>>({});
  const [loading, , withLoading] = useLoading<void>();

  const profileComplete = useMemo(
    () =>
      !!(
        user.address?.city &&
        user.address.postalCode &&
        user.address.province &&
        user.address.street &&
        user.firstName &&
        user.lastName
      ),
    [user.address, user.firstName, user.lastName]
  );

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        profileComplete,
        load: () =>
          withLoading(async () => {
            const user = await whoami();
            setUser(user);
          }),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const LoginGaurd: FC = ({ children }) => {
  const [notLoggedIn, setNotLoggedIn] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const { load: hydrate, loading } = useContext(UserContext);
  const toast = useToast();

  useEffect(() => {
    (async () => {
      try {
        await whoami();
        setLoggedIn(true);
      } catch (e) {
        if (e instanceof NotAuthenticatedError) {
          toast({
            title: "Session expired",
            status: "error",
            duration: 3000,
            position: "top",
          });
          setNotLoggedIn(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedIn && !loading.loaded && !loading.loading) {
      hydrate();
    }
  }, [loggedIn, hydrate, loading]);

  if (notLoggedIn) {
    return <Redirect to="/login" />;
  }
  if (loggedIn) {
    return <>{children}</>;
  }
  return null;
};
