import React, { forwardRef, useCallback, useState } from "react";
import {
  Heading,
  useToast,
  Input,
  Button,
  Stack,
  Alert,
} from "@chakra-ui/core";
import { useLoading } from "../../hooks/useLoading";
import { Section } from "./Shared";
import { passwordReset } from "../../lib/api/user";
import { Form, Formik } from "formik";
import { FormikField } from "../shared/forms";
import { passwordResetSchema } from "./schema";

interface IInitialFormValues {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const initialFormValues = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
};

export interface PasswordResetProps
  extends React.HtmlHTMLAttributes<HTMLFormElement> {}

export const PasswordReset = forwardRef<HTMLFormElement, PasswordResetProps>(
  () => {
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const [loading, , withLoading] = useLoading<void>();
    const toast = useToast();

    const handleSubmit = useCallback(
      (values) => {
        withLoading(async () => {
          try {
            setSubmitError({});
            await passwordReset(values);

            toast({
              title: "Password has been reset.",
              description: "Your profile has successfully been updated",
              status: "success",
              duration: 2000,
              position: "top",
              isClosable: true,
            });
          } catch (e) {
            setSubmitError({ message: e.message });
          }
        });
      },
      [withLoading, setSubmitError, toast]
    );

    return (
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={passwordResetSchema}
      >
        <Form>
          <Heading as="h3" size="lg" paddingBottom="0.5em" paddingTop="0.5em">
            Password Reset
          </Heading>
          {submitError.message && (
            <Alert status="error" marginBottom="0.5em">
              {submitError.message}
            </Alert>
          )}
          <Section>
            <FormikField<IInitialFormValues>
              name="currentPassword"
              label="Current Password"
              placeholder="Current Password"
              isRequired
            >
              {({ field }) => (
                <Input
                  {...field}
                  type="password"
                  placeholder="Current Password"
                />
              )}
            </FormikField>
            <FormikField<IInitialFormValues>
              name="password"
              label="New Password"
              isRequired
            >
              {({ field }) => (
                <Input {...field} type="password" placeholder="Password" />
              )}
            </FormikField>
            <FormikField<IInitialFormValues>
              name="confirmPassword"
              label="Confirm Password"
              isRequired
            >
              {({ field }) => (
                <Input
                  {...field}
                  type="password"
                  placeholder="Confirm Password"
                />
              )}
            </FormikField>
          </Section>
          <br />
          <Stack gridArea="button" align="center">
            <Button
              data-testid="reset-password"
              type="submit"
              isLoading={loading.loading}
              variantColor="blue"
            >
              Reset Password
            </Button>
          </Stack>
        </Form>
      </Formik>
    );
  }
);
