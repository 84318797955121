import { request } from "./base";

export type Bucket = {
  id: string;
  name: string;
  tag: string;
  usage: string;
  keys: {
    id: string;
    name: string;
    access: ":full" | ":read";
  }[];
};

export const buckets = async () => {
  const response = await request("/buckets", {});
  const result = await response.json();
  return result.data;
};

export const bucket = async (id: string): Promise<Bucket> => {
  const response = await request(`/buckets/${id}`, {});
  const result = await response.json();
  return result.data;
};

export type Key = {
  id: string;
  name: string;
  accessKey: string;
  secretKey: string;
  access: ":read" | ":full";
  master: string;
};

export const getKeys = async (): Promise<Key[]> => {
  const response = await request("/keys", {});
  const result = await response.json();
  return result.data;
};

export type CreateKeyParams = {
  name: string;
  perms: "read" | "full";
};

export const createKey = async (params: CreateKeyParams) => {
  const response = await request("/keys", {
    method: "POST",
    body: JSON.stringify(params),
  });
  const result = await response.json();
  return result;
};

export type AddKeyToBucket = {
  bucket: string;
  key: string;
};

export const addKeyToBucket = async ({ bucket, ...params }: AddKeyToBucket) => {
  const response = await request(`/buckets/${bucket}/keys`, {
    method: "POST",
    body: JSON.stringify(params),
  });
  const result = await response.json();
  return result;
};

export type UpdateBucketDisplayName = {
  bucket: string;
  name: string;
};

export const updateBucketDisplayName = async ({
  bucket,
  ...params
}: UpdateBucketDisplayName) => {
  const response = await request(`/buckets/${bucket}`, {
    method: "PATCH",
    body: JSON.stringify(params),
  });
  const result = await response.json();
  return result;
};

export const removeKeyFromBucket = async ({
  bucket,
  ...params
}: AddKeyToBucket) => {
  const response = await request(`/buckets/${bucket}/keys`, {
    method: "DELETE",
    body: JSON.stringify(params),
  });
  const result = await response.json();
  return result;
};

export type DeleteBucket = {
  bucket: string;
  otp?: string;
};

export const deleteBucket = async ({ bucket, ...params }: DeleteBucket) => {
  const response = await request(`/buckets/${bucket}/otp`, {
    method: "DELETE",
    body: JSON.stringify(params),
  });
  const result = await response.json();
  return result;
};

export const deleteBucketConfirmed = async ({
  bucket,
  ...params
}: DeleteBucket) => {
  const response = await request(`/buckets/${bucket}`, {
    method: "DELETE",
    body: JSON.stringify(params),
  });
  const result = await response.json();
  return result;
};

export const deleteKey = async (keyId: string) => {
  const response = await request(`/keys/${keyId}`, {
    method: "DELETE",
  });
  const result = await response.json();
  return result;
};
