import { request } from "./base";

export type User = {
  email: string;
};
export type Profile = {
  email: string;
  cell: string;
  country: string;
  firstName: string;
  lastName: string;
  secondaryEmail: string;
  billingEmail: string;
  technicalEmail: string;
  address: {
    street: string;
    extra: string;
    city: string;
    province: string;
    postalCode: string;
  };
};

export const whoami = (): Promise<Profile> =>
  request("/identity/whoami")
    .then((res) => res.json())
    .then(({ data }) => data);

export const login = async (fields: { email: string; password: string }) => {
  const response = await request("/identity/login", {
    method: "POST",
    body: JSON.stringify(fields),
  });
  return response.json();
};

export type RegistrationDetails = {
  email: string;
  password: string;
};

export const register = async (details: RegistrationDetails) => {
  return request("/identity/register", {
    method: "POST",
    body: JSON.stringify(details),
  });
};

export const confrimRegistration = async (fields: {
  email: string;
  otpEmail: string;
}) => {
  return request("/identity/register/confirm", {
    method: "POST",
    body: JSON.stringify(fields),
  });
};

export const resendRegistrationOtp = async (fields: { email: string }) => {
  return request("/identity/register/resend-otp", {
    method: "POST",
    body: JSON.stringify(fields),
  });
};

export const resetRequest = async (fields: { email: string }) => {
  return request("/identity/reset-password", {
    method: "POST",
    body: JSON.stringify(fields),
  });
};

export const confirmResetRequest = async (fields: {
  token: string;
  password: string;
  confirmPassword: string;
}) => {
  return request("/identity/reset-password/complete", {
    method: "POST",
    body: JSON.stringify(fields),
  });
};

export const passwordReset = async (fields: {
  password: string;
  confirmPassword: string;
}) => {
  return request("/identity/password-reset", {
    method: "POST",
    body: JSON.stringify(fields),
  });
};
