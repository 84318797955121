import React, {
  forwardRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from "react";
import classnames from "classnames";
import {
  Heading,
  InputGroup,
  Input,
  Stack,
  Button,
  useToast,
  Box,
  Alert,
  AlertIcon,
  InputLeftAddon,
} from "@chakra-ui/core";
import * as yup from "yup";
import { OrderWizard } from "../../order-wizard";
import { useLoading } from "../../../hooks/useLoading";
import { Container, Section } from "../../profile/Shared";
import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { Loader } from "../../shared";
import { history } from "../../../lib/history";
import { ValidationError, NotAuthenticatedError } from "../../../lib/api/base";
import {
  Account,
  creditCards,
  account,
  updateAccountWithCC,
  CreditCard,
} from "../../../lib/api/billing";
import { useApiCall } from "../../../hooks/useApiCall";
import { countries } from "../../../lib/countries";
import { UserContext } from "../../../contexts/UserContext";
import { FormikField, FormikSelect } from "../../shared/forms";
import { useFormOptions } from "../../order-wizard-new/new-bucket/steps/Payment";
import { Form, Formik } from "formik";
import { Profile } from "../../../lib/api/user";
import { cellValidator } from "../../../lib/schemata";

export interface ManageAccountProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const existingCardSchema = yup.object().shape({
  country: yup.string().required("Required"),
  cell: cellValidator,
  cc_existingCard: yup.string().required("Required"),
});

export const SectionContainer = styled(Box)`
  display: grid;
  grid-template-areas:
    "basic"
    "credit"
    "button";
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-areas:
      "basic basic"
      "credit credit"
      "button";
  }
  grid-gap: 1em;
`;

const TierContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  margin-top: 1.5em;
`;

export const ManagePaymentDetails = forwardRef<
  HTMLDivElement,
  ManageAccountProps
>(({ children, className, ...props }, ref) => {
  const { user } = useContext(UserContext);
  const [ccs, ,reload] = useApiCall(() => creditCards(), [], []);
  const [accloading, , withAccLoading] = useLoading();
  const [userAccount, setUserAccount] = useState<Partial<Account>>({});
  const [hasAccount, setHasAccount] = useState(false);
  const [submitError, setSubmitError] = useState<{ message?: string }>({});
  const [loading, , withLoading] = useLoading<void>();
  const toast = useToast();
  const defaultCountry = useMemo(
    () =>
      countries.find((c) => c.code === user.country) || {
        code: "ZA",
        dialCode: "+27",
        name: "South Africa",
      },
    [user.country]
  );
  const [cellPrefix, setCellPrefix] = useState<string>(
    defaultCountry.dialCode || "+27"
  );

  const [countryCode, setCountryCode] = useState<string>(
    defaultCountry.code || "ZA"
  );
  const options = useFormOptions({ ccs });

  const loadAccount = useCallback(
    () =>
      withAccLoading(async () => {
        if (!accloading.loading && !accloading.loaded) {
          try {
            const acc = await account();
            setUserAccount(acc.account);
            // setUserStatements([
            //   {
            //     date: "Mon Jun 01 02:00:00 SAST 2015",
            //     debit: 10000,
            //     credit: 0,
            //     balance: -10000,
            //     description: "INV-0448",
            //     type: "Invoice",
            //   },
            //   {
            //     date: "Mon Jun 01 02:00:00 SAST 2015",
            //     debit: 0,
            //     credit: 2000,
            //     balance: -8000,
            //     description: "RCP0000773",
            //     type: "Customer Receipt",
            //   },
            // ]);
            setHasAccount(true);
          } catch (e) {
            if (e instanceof NotAuthenticatedError) {
              setHasAccount(false);
            }
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const useInitialFormValues = (user: Partial<Profile>, ccs: CreditCard[]) => {
    const defaultCountry = useMemo(
      () =>
        countries.find((c) => c.code === user.country) || {
          code: "ZA",
          dialCode: "+27",
          name: "South Africa",
        },
      [user.country]
    );
    const defaultCell = useMemo(
      () =>
        user.cell ? user.cell.replace(defaultCountry?.dialCode || "", "") : "",
      [defaultCountry, user.cell]
    );

    useEffect(() => {
      setCountryCode(defaultCountry.code ? defaultCountry.code : "ZA");
      setCellPrefix(defaultCountry.dialCode ? defaultCountry.dialCode : "+27");
    }, [defaultCell, defaultCountry]);

    return {
      cell: defaultCell,
      country: countryCode,
      cc_existingCard:  ccs.find(c => c.id === userAccount.creditCard?.id)?.id || "",
    };
  };
  
  interface IInitialFormValues extends ReturnType<typeof useInitialFormValues> {}
  
  const handleSubmit = useCallback(
    (values: IInitialFormValues) => {
      withLoading(async () => {
        try {
          setSubmitError({});

          const submitVals = {
            id: userAccount.id,
            name: userAccount.name,
            companyNumber: userAccount.companyNumber,
            vatNumber: userAccount.vatNumber,
            address: userAccount.address,
            cell: `${cellPrefix}${values.cell}`,
            country: values.country,
          };
            await updateAccountWithCC({
              ...submitVals,
              ccId: (ccs.find((c) => c.id === values.cc_existingCard) || {}).id,
            });

          await reload();
          toast({
            title: "Account updated.",
            description: "Your account has successfully been updated",
            status: "success",
            duration: 2000,
            position: "top",
            isClosable: true,
          });
        } catch (e) {
          console.log(e);
          if (e instanceof ValidationError) {
            return setSubmitError({ message: e.message });
          }
          if (e instanceof NotAuthenticatedError) {
            toast({
              title: "Session expired",
              status: "error",
              duration: 3000,
              position: "top",
            });
            return history.push("/login");
          }
          return setSubmitError({
            message: "An unexpected error has occurred",
          });
        }
      });
    },
    [reload, toast, userAccount, withLoading, ccs, cellPrefix]
  );

  useEffect(() => {
    loadAccount();
  }, [loadAccount, ccs]);

  const initialFormValues = useInitialFormValues(user, ccs);

  if (accloading.loading) {
    return <Loader>fetching account...</Loader>;
  }

  if (!hasAccount) {
    return (
      <TierContainer>
        <OrderWizard />
      </TierContainer>
    );
  }

  const handleCountryChange = (e: string) => {
    setCellPrefix(countries.find(c => c.code === e)?.dialCode || "");
    setCountryCode(e);
  };

  return (
    <Container {...props} ref={ref} className={classnames("", {}, className)}>
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={existingCardSchema}
      >
        <Form>
          <Heading as="h3" size="lg" paddingBottom="0.5em" paddingTop="0.5em">
            Update your payment details (You are on the{" "}
            {userAccount.tier === "paid" ? "Paid" : "Free"} tier)
          </Heading>
          {submitError.message && (
            <Alert status="error" marginBottom="0.5em">
              {submitError.message}
            </Alert>
          )}
          <SectionContainer>
            <Section gridArea="basic">
              <Heading as="h3" size="md" paddingBottom="0.25em">
                Contact information
              </Heading>
              <FormikSelect<IInitialFormValues>
                name="country"
                label="Country"
                options={options.country.all()}
                onChange={handleCountryChange}
                testId="country"
              />
              <FormikField<IInitialFormValues>
                label="Cellphone number"
                name="cell"
              >
                {({ field }) => (
                  <InputGroup>
                    <InputLeftAddon>{cellPrefix}</InputLeftAddon>
                    <Input
                      {...field}
                      name={field.name}
                      placeholder="8889992"
                    />
                  </InputGroup>
                )}
              </FormikField>
            </Section>
            <Section gridArea="credit">
              <Heading as="h3" size="md" paddingBottom="0.25em">
                Credit Card
                
              </Heading>
              {options.creditCards.all().length === 0 && (
                  <Alert status="info" marginBottom="0.5em">
                      <AlertIcon /> 
                      You don't currently have any Credit Cards added. Please go to Billing and then Credit Cards tab in order to add your first Credit Card.
                  </Alert>
              )}     <>
                  <FormikSelect<IInitialFormValues>
                      name="cc_existingCard"
                      options={options.creditCards.all()}
                      />
              </>
            </Section>
            <Stack gridArea="button" alignItems="center">
              <Button
                type="submit"
                isLoading={loading.loading}
                variantColor="blue"
              >
                {userAccount.tier === "free" ? "Upgrade Tier" : "Update"}
              </Button>
            </Stack>
          </SectionContainer>
        </Form>
      </Formik>
    </Container>
  );
});
