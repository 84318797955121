import { request } from "./base";

export type BucketOrder = {
  tag: string;
};

export const placeOrder = async (
  order: BucketOrder
): Promise<{ message: string }> => {
  const response = await request("/orders", {
    method: "POST",
    body: JSON.stringify(order),
  });
  const { data } = await response.json();
  return data;
};

export type FirstBucketOrderParams = {
  tier: string;
  cell?: string;
  country?: string;
  account: {
    name: string;
    companyNumber: string;
    vatNumber: string;
    address: {
      street: string;
      extra: string;
      city: string;
      province: string;
      postalCode: string;
    };
  };
  existingCard?: string;
  tag: string;
};

export const placeFirstBucketOrder = async (
  order: FirstBucketOrderParams
): Promise<{ message: string }> => {
  const response = await request("/orders/first", {
    method: "POST",
    body: JSON.stringify(order),
  });
  const { data } = await response.json();
  return data;
};

export type Order = {
  account: string;
  createdAt: Date;
  paymentFreq: string;
  product: string;
  resource: string;
  status: string;
};

export const orders = async (): Promise<Order[]> => {
  const response = await request("/orders", {});
  const { data } = await response.json();
  return data.orders.map((order: any) => ({
    ...order,
    createdAt: new Date(Date.parse(order.createdAt)),
  }));
};
