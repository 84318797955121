import React, { forwardRef } from "react";
import classnames from "classnames";
import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { Box, Heading, Button, Link } from "@chakra-ui/core";
import { Section } from "../../profile/Shared";

export interface ContactUsProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  margin-top: 1em;
`;

export const SectionContainer = styled(Box)`
  display: grid;
  grid-template-areas: "email";
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-areas: "email";
  }
  grid-gap: 1em;
`;

const SupportLinks = styled(Box)`
  display: flex;
  flex-direction: column;

  div:first-child {
    margin-bottom: 2em;
  }

  @media (min-width: ${theme.breakpoints[0]}) {
    flex-direction: row;
    div:first-child {
      margin-right: 2em;
      margin-bottom: 0;
    }
  }
`;

export const ContactUs = forwardRef<HTMLDivElement, ContactUsProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Container {...props} ref={ref} className={classnames("", {}, className)}>
        <Heading as="h3" size="lg" paddingTop="0.5em">
          Contact Us
        </Heading>
        <SectionContainer>
          <Section gridArea="email">
            <SupportLinks>
              <div>
                <Heading as="h5" paddingBottom="1em" size="sm">
                  Log a Support Issue
                </Heading>
                <Button variantColor="blue">
                  <Link
                    data-testid="log-issue"
                    isExternal
                    href="https://support.cloudafrica.net/support/home"
                  >
                    Log Issue
                  </Link>
                </Button>
              </div>
              <div>
                <Heading as="h5" paddingBottom="1em" size="sm">
                  Get in Touch
                </Heading>
                <Button variantColor="blue">
                  <Link
                    data-testid="email-us"
                    isExternal
                    href="mailto:support@cloudafrica.net?subject=Help needed from Storage Support"
                  >
                    Email Us
                  </Link>
                </Button>
              </div>
            </SupportLinks>
          </Section>
        </SectionContainer>
      </Container>
    );
  }
);
