import React, { FC } from "react";
import classnames from "classnames";
import { useQueryParams, NumberParam } from "use-query-params";
import { theme } from "../../theme";
import { Flex, Link, Icon } from "@chakra-ui/core";
import styled from "@emotion/styled";

const PagerRow = styled.div`
  box-shadow: ${theme.shadows.md};
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[50]};

  &:hover {
    background-color: ${theme.colors.gray[50]};
  }
  &:active {
    border: solid 1px ${theme.colors.blue[400]};
    background-color: ${theme.colors.gray[100]};
  }
  a {
    text-decoration: none;
  }
`;

const PagerWrapper = styled.ul`
  .paginator-active {
    border: solid 1px ${theme.colors.blue[600]};
  }

  li {
    display: inline-block;
  }

  li a.disabled {
    cursor: not-allowed;
  }

  li a {
    padding: 0 1em;
    line-height: 2em;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const Pager: FC<{
  pagination: {
    pages: number[];
    showFirst: boolean;
    showNext: boolean;
    showLast: boolean;
    showPages: boolean;
    showPagination: boolean;
    showPrevious: boolean;
    begin: number;
    end: number;
    lastPageNumber: number;
  };
}> = ({ pagination }) => {
  const [paginator, setPaginator] = useQueryParams({
    page: NumberParam,
    perPage: NumberParam
  });

  return (
    <Flex justifyContent="center">
      <PagerWrapper className="pagination">
        {pagination.showFirst && (
          <li>
            <Link onClick={() => setPaginator({ page: 1 })}>First</Link>
          </li>
        )}

        <li>
          <Link
            disabled={!pagination.showPrevious}
            className={classnames("", {
              disabled: !pagination.showPrevious
            })}
            onClick={() => {
              if (!pagination.showPrevious) {
                return;
              }
              setPaginator({ page: (paginator.page || 2) - 1 });
            }}
          >
            <Icon name="chevron-left" />
          </Link>
        </li>

        {pagination.pages.map(page => (
          <li
            className={classnames("", {
              active: paginator.page === page
            })}
          >
            <PagerRow
              className={classnames("", {
                "paginator-active": paginator.page === page
              })}
            >
              <Link onClick={() => setPaginator({ page: page })}>{page}</Link>
            </PagerRow>
          </li>
        ))}

        <li>
          <Link
            disabled={!pagination.showNext}
            className={classnames("", {
              disabled: !pagination.showNext
            })}
            onClick={() => {
              if (!pagination.showNext) {
                return;
              }
              setPaginator({ page: (paginator.page || 1) + 1 });
            }}
          >
            <Icon name="chevron-right" />
          </Link>
        </li>

        {pagination.showLast && (
          <li>
            <Link
              onClick={() => setPaginator({ page: pagination.lastPageNumber })}
            >
              Last
            </Link>
          </li>
        )}
      </PagerWrapper>
    </Flex>
  );
};
