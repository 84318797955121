import { Collapse, Box, Heading, Icon, Stack, theme } from "@chakra-ui/core";
import React, { useState } from "react";

export const Collapsable = ({
  startOpen = false,
  heading,
  testId,
  children,
}: {
  startOpen?: boolean;
  heading: string;
  testId?: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  return (
    <Stack>
      <Heading as="h3" size="sm" data-testid={testId}>
        <Box
          onClick={() => setIsOpen((o) => !o)}
          display="inline-flex"
          textDecoration="underline"
          alignItems="center"
          cursor="pointer"
        >
          {heading}
          <Icon ml={1} name={isOpen ? "chevron-up" : "chevron-down"} />
        </Box>
      </Heading>
      <Collapse isOpen={isOpen}>
        <Box rounded="md" bg={theme.colors.gray[100]} padding="1em">
          {children}
        </Box>
      </Collapse>
    </Stack>
  );
};
