import React, { forwardRef } from "react";
import classnames from "classnames";
import { Tabs, TabPanels, TabPanel } from "@chakra-ui/core";
import { Route, useRouteMatch } from "react-router";
import { ContactUs } from "./contact";
import { EscalationMatrix } from "./escalation";
import { SupportSLA } from "./support";
import { history } from "../../lib/history";
import { TabList, Tab } from "../shared";

export interface SupportProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const tabIndicies = {
  "contact-us": 0,
  "support-sla": 1,
  "escalation-matrix": 2,
};

export const Support = forwardRef<HTMLDivElement, SupportProps>(
  ({ children, className, ...props }, ref) => {
    const match = useRouteMatch<{
      tab: "contact-us" | "support-sla" | "escalation-matrix";
    }>("/support/:tab");
    return (
      <div {...props} ref={ref} className={classnames("", {}, className)}>
        <Tabs isFitted index={tabIndicies[match?.params.tab || "contact-us"]}>
          <TabList>
            <Tab onClick={() => history.push("/support/contact-us")}>
              Contact Us
            </Tab>
            <Tab onClick={() => history.push("/support/support-sla")}>
              Support SLA
            </Tab>
            <Tab onClick={() => history.push("/support/escalation-matrix")}>
              Escalation Matrix
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Route path="/support/contact-us" render={() => <ContactUs />} />
            </TabPanel>
            <TabPanel>
              <Route
                path="/support/support-sla"
                render={() => <SupportSLA />}
              />
            </TabPanel>
            <TabPanel>
              <Route
                path="/support/escalation-matrix"
                render={() => <EscalationMatrix />}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    );
  }
);
