import React, { forwardRef, useCallback, useContext, useMemo } from "react";
import classnames from "classnames";
import {
  Heading,
  Stack,
  Button,
  useToast,
  Tabs,
  TabPanels,
  TabPanel,
} from "@chakra-ui/core";
import { useLoading } from "../../hooks/useLoading";
import { UserContext } from "../../contexts/UserContext";
import { updateProfile } from "../../lib/api/profile";
import { PasswordReset } from "./PasswordReset";
import { Container, SectionContainer, Section } from "./Shared";
import { Tab, TabList } from "../shared";
import { Profile as IProfile } from "../../lib/api/user";
import { Form, Formik } from "formik";
import { FormikField } from "../shared/forms";
import { profileFormSchema } from "./schema";

interface IInitialFormValues {
  firstName: string;
  lastName: string;
  secondaryEmail: string;
  technicalEmail: string;
  billingEmail: string;
  street: string;
  extra: string;
  city: string;
  province: string;
  postalCode: string;
}

const useInitFormValues = (user: Partial<IProfile>) => {
  return useMemo(
    () => ({
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      secondaryEmail: user.secondaryEmail || "",
      technicalEmail: user.technicalEmail || "",
      billingEmail: user.billingEmail || "",
      street: user.address?.street || "",
      extra: user.address?.extra || "",
      city: user.address?.city || "",
      province: user.address?.province || "",
      postalCode: user.address?.postalCode || "",
    }),
    [user]
  );
};

export interface ProfileProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const Profile = forwardRef<HTMLDivElement, ProfileProps>(
  ({ children, className, ...props }, ref) => {
    const { user, load } = useContext(UserContext);
    const [loading, , withLoading] = useLoading<void>();
    const toast = useToast();

    const handleSubmit = useCallback(
      (values: IInitialFormValues) => {
        withLoading(async () => {
          await updateProfile({
            firstName: values.firstName,
            lastName: values.lastName,
            technicalEmail: values.technicalEmail,
            billingEmail: values.billingEmail,
            secondaryEmail: values.secondaryEmail,
            address: {
              street: values.street,
              extra: values.extra,
              city: values.city,
              province: values.province,
              postalCode: values.postalCode,
            },
          });
          await load();
          toast({
            title: "Profile updated.",
            description: "Your profile has successfully been updated",
            status: "success",
            duration: 2000,
            position: "top",
            isClosable: true,
          });
        });
      },
      [withLoading, load, toast]
    );

    const initialFormValues = useInitFormValues(user);

    return (
      <Container {...props} ref={ref} className={classnames("", {}, className)}>
        <Tabs isFitted>
          <TabList>
            <Tab data-testid="profile">Profile</Tab>
            <Tab data-testid="password-reset">Password Reset</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Formik
                enableReinitialize
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
                validationSchema={profileFormSchema}
              >
                <Form>
                  <Heading
                    as="h3"
                    size="lg"
                    paddingBottom="0.5em"
                    paddingTop="0.5em"
                  >
                    Profile information
                  </Heading>
                  <SectionContainer>
                    <Section gridArea="basic">
                      <Heading as="h3" size="md" paddingBottom="0.25em">
                        Basic information
                      </Heading>
                      <FormikField<IInitialFormValues>
                        name="firstName"
                        label="First Name"
                        isRequired
                        placeholder="e.g. James"
                        inputTestId="profile-first-name"
                      />
                      <FormikField<IInitialFormValues>
                        name="lastName"
                        label="Last Name"
                        isRequired
                        placeholder="e.g. Applebee"
                        inputTestId="profile-last-name"
                      />
                    </Section>
                    <Section gridArea="contact">
                      <Heading as="h3" size="md" paddingBottom="0.25em">
                        Contact information
                      </Heading>
                      <Stack>
                        <FormikField<IInitialFormValues>
                          name="secondaryEmail"
                          label="Secondary Email"
                          placeholder="e.g. bob@example.com"
                        />
                        <FormikField<IInitialFormValues>
                          name="billingEmail"
                          label="Billing Email"
                          placeholder="e.g. bob@example.com"
                        />
                        <FormikField<IInitialFormValues>
                          name="technicalEmail"
                          label="Technical Email"
                          placeholder="e.g. bob@example.com"
                        />
                      </Stack>
                    </Section>
                    <Section gridArea="address">
                      <Heading as="h3" size="md" paddingBottom="0.25em">
                        Address
                      </Heading>
                      <Stack>
                        <FormikField<IInitialFormValues>
                          name="street"
                          label="Street Address"
                          isRequired
                          placeholder="e.g. 123 Willoughby way"
                          inputTestId="profile-street"
                        />
                        <FormikField<IInitialFormValues>
                          name="extra"
                          label="Extra detail"
                          placeholder="e.g. Apartment 203"
                          inputTestId="profile-extra-detail"
                        />
                        <FormikField<IInitialFormValues>
                          name="city"
                          label="City"
                          isRequired
                          placeholder="e.g. Cape Town"
                          inputTestId="profile-city"
                        />
                        <FormikField<IInitialFormValues>
                          name="province"
                          label="Province"
                          isRequired
                          placeholder="e.g. Western Cape"
                          inputTestId="profile-province"
                        />
                        <FormikField<IInitialFormValues>
                          name="postalCode"
                          label="Postal Code"
                          isRequired
                          placeholder="e.g. 8001"
                          inputTestId="profile-postal-code"
                        />
                      </Stack>
                    </Section>
                    <Stack gridArea="button" align="center">
                      <Button
                        data-testid="update"
                        type="submit"
                        isLoading={loading.loading}
                        variantColor="blue"
                      >
                        Update
                      </Button>
                    </Stack>
                  </SectionContainer>
                </Form>
              </Formik>
            </TabPanel>
            <TabPanel>
              <PasswordReset />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    );
  }
);
