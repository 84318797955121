import React, {
  forwardRef,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import classnames from "classnames";
import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import { Alert, useToast } from "@chakra-ui/core";

import { Loader } from "../../shared";
import { GetStartedForm } from "./steps/GetStarted";
import { PaymentInputs } from "./steps/Payment";
import { ConfirmForm } from "./steps/Confirm";
import { Steps } from "./StepIndicator";

import { history } from "../../../lib/history";
import { theme } from "../../../theme";
import {
  creditCards,
  account,
  updateAccountWithCC,
  Account,
  CreditCard,
} from "../../../lib/api/billing";
import { UserContext } from "../../../contexts/UserContext";
import useSteppedState from "../../../hooks/useSteppedState";
import { useApiCall } from "../../../hooks/useApiCall";
import {
  buckets as apiBuckets,
  Bucket as TBucket,
} from "../../../lib/api/buckets";

import { getSchemaForStep } from "./schema";
import { countries } from "../../../lib/countries";
import { useLoading } from "../../../hooks/useLoading";
import { placeOrder } from "../../../lib/api/orders";
import { NotAuthenticatedError, ValidationError } from "../../../lib/api/base";
import { Complete } from "./steps/Complete";
import { Profile } from "../../../lib/api/user";
import { FormValidator } from "./FormValidator";
import { useScrollToTop } from "../../../hooks/useScrollToTop";

export interface NewBucketWizardProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const WizardWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[100]};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.md};
  padding: 1em;
  width: 100%;

  @media (min-width: ${theme.breakpoints[1]}) {
    width: 80%;
  }
`;

export interface IInitialFormValues {
  tagName: string;
  tier: Account["tier"];
  cell: string;
  country: string;
  cc_existingCard: string;
}

const useInitFormValues = (
  user: Partial<Profile>,
  userAccount: Account,
  ccs: CreditCard[]
) => {
  const defaultCountry = useMemo(
    () =>
      countries.find((c) => c.code === user.country) || {
        code: "ZA",
        dialCode: "+27",
        name: "South Africa",
      },
    [user.country]
  );

  const defaultCell = useMemo(
    () =>
      user.cell ? user.cell.replace(defaultCountry?.dialCode || "", "") : "",
    [defaultCountry, user.cell]
  );

  return {
    tagName: "",
    tier: userAccount?.tier || "",
    cell: defaultCell,
    country: defaultCountry.code,
    cc_existingCard: ccs[0]?.id || "",
  } as IInitialFormValues;
};

export const NewBucketWizard = forwardRef<HTMLDivElement, NewBucketWizardProps>(
  ({ children, className, ...props }, ref) => {
    const { user } = useContext(UserContext);
    const [ccs, ccsLoading] = useApiCall(() => creditCards(), [], []);
    const [userAccount, userAccountLoading] = useApiCall(
      () => account(),
      {},
      []
    );
    const [formLoading, , withLoading] = useLoading<void>();
    const { step, setStep, reached } = useSteppedState(4);
    const [createdBucketName, setCreatedBucketName] = useState<string>("");
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const initialFormValues = useInitFormValues(user, userAccount.account, ccs);
    const toast = useToast();

      useScrollToTop([step]);

    const handleSubmit = useCallback(
      (values: IInitialFormValues) => {
        withLoading(async () => {
          try {
            setSubmitError({});
            if (
              values.tier !== "free" &&
              userAccount.account.paymentMethod !== ":bank-transfer" &&
              values.cc_existingCard
            ) {
              const dialCode = (
                countries.find((c) => c.code === values.country) || {
                  code: "ZA",
                  dialCode: "+27",
                  name: "South Africa",
                }
              ).dialCode;

                await updateAccountWithCC({
                  ...userAccount.account,
                  cell: `${dialCode}${values.cell}`,
                  country: values.country,
                  ccId: (ccs.find((c) => c.id === values.cc_existingCard) || {})
                    .id,
                });
 
            }

            await placeOrder({
              tag: values.tagName,
            });

            await apiBuckets().then((data) => {
              setCreatedBucketName(
                data.find((bucket: TBucket) => bucket.tag === values.tagName)
                  ?.name
              );
            });

            toast({
              title: "Bucket created.",
              description: "Your bucket has successfully been created",
              status: "success",
              duration: 2000,
              position: "top",
              isClosable: true,
            });

            setStep(4);
          } catch (e) {
            if (
              e instanceof ValidationError &&
              /credit card/i.test(e.message)
            ) {
              setSubmitError({ message: e.message });
              setStep(2);
            } else {
              if (e instanceof NotAuthenticatedError) {
                toast({
                  title: "Session expired",
                  status: "error",
                  duration: 3000,
                  position: "top",
                });
                return history.push("/login");
              }
              setSubmitError({ message: e.message });
            }
          }
        });
      },
      [ccs, setStep, toast, userAccount.account, withLoading]
    );

    const [formConfirmed, setFormConfirmed] = useState<boolean>(false);

    if (
      ccsLoading.loading ||
      !ccsLoading.loaded ||
      userAccountLoading.loading ||
      !userAccountLoading.loaded
    ) {
      return <Loader>fetching account...</Loader>;
    }

    return (
      <div {...props} ref={ref} className={classnames("", {}, className)}>
        <WizardWrapper>
          {submitError.message && (
            <Alert
              status="error"
              marginBottom="1em"
              data-testid="alert-message"
            >
              {submitError.message}
            </Alert>
          )}
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={getSchemaForStep(
              step,
              userAccount.account.paymentMethod
            )}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setFieldValue,
              isValid,
              touched,
              validateForm,
            }) => (
              <FormValidator
                step={step}
                validateForm={validateForm}
              >
                <Steps
                  step={step}
                  reachedStep={reached}
                  setStep={setStep}
                  tier={values.tier}
                />
                <Form>
                  {step === 1 && (
                    <GetStartedForm
                      setStep={setStep}
                      values={values}
                      errors={errors}
                      touched={touched}
                      isValid={isValid}
                      setFieldValue={setFieldValue}
                      userAccount={userAccount.account}
                    />
                  )}
                  {step === 2 && (
                    <PaymentInputs
                      setStep={setStep}
                      userAccount={userAccount.account}
                      ccs={ccs}
                      isValid={isValid}
                      values={values}
                    />
                  )}
                  {step === 3 && (
                    <ConfirmForm
                      values={values}
                      ccs={ccs}
                      formConfirmed={formConfirmed}
                      setFormConfirmed={setFormConfirmed}
                      isLoading={formLoading.loading}
                    />
                  )}
                  {step === 4 && (
                    <Complete createdBucketName={createdBucketName} />
                  )}
                </Form>
              </FormValidator>
            )}
          </Formik>
        </WizardWrapper>
      </div>
    );
  }
);
