import { useCallback, useState } from "react";

const useStepState = (numSteps: number) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [reachedStep, setReachedStep] = useState(1);

  const inc = useCallback(() => {
    if (currentStep === numSteps) return;

    const nextStep = currentStep + 1;

    setCurrentStep(nextStep);
    setReachedStep(currentStep);
  }, [currentStep, numSteps, setCurrentStep, setReachedStep])

  const dec = useCallback(() => {
    if (currentStep === 1) return;

    const nextStep = currentStep - 1;

    setCurrentStep(nextStep);
  }, [currentStep, setCurrentStep])

  const setStep = (step: number) => {
    if (step <= numSteps && step > 0) {
      setCurrentStep(step);
      if (step > reachedStep) setReachedStep(step)
    }
  }

  return { step: currentStep, reached: reachedStep, inc, dec, setStep };
};

export default useStepState;
