import React from "react";
import { Button } from "@chakra-ui/core";
import styled from "@emotion/styled";
import { theme } from "../../../theme";

const StepControlContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-gap: 1em;
  padding-top: 1em;
  padding-bottom: 1em;

  grid-template-columns: 1fr 1fr;

  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 8em 8em;
  }

  @media (min-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 10em 10em;
  }
`;

interface IProps {
  onCancel: () => void;
  onConfirm?: () => void;
  isValid: boolean;
  isLoading?: boolean;
  isSubmit?: boolean;
  confirmText?: string;
  cancelText?: string;
  confirmTestId?: string;
  cancelTestId?: string;
}

export const StepControls = ({
  onConfirm,
  isSubmit,
  onCancel,
  isValid,
  isLoading,
  confirmText = "Next",
  cancelText = "Cancel",
  confirmTestId = "next",
  cancelTestId = "cancel",
}: IProps) => {
  return (
    <StepControlContainer>
      <Button
        onClick={onCancel}
        variantColor="blue"
        variant="outline"
        data-testid={cancelTestId}
      >
        {cancelText}
      </Button>
      <Button
        isLoading={isLoading}
        isDisabled={!isValid}
        variantColor="blue"
        variant="solid"
        data-testid={confirmTestId}
        onClick={onConfirm}
        type={isSubmit ? "submit" : "button"}
      >
        {confirmText}
      </Button>
    </StepControlContainer>
  );
};
