import React, {
  forwardRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import classnames from "classnames";
import { useRouteMatch } from "react-router";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useApiCall } from "../../hooks/useApiCall";
import {
  bucket as getBucket,
  getKeys,
  addKeyToBucket,
  removeKeyFromBucket,
  updateBucketDisplayName,
} from "../../lib/api/buckets";
import { Loader } from "../shared";
import {
  Heading,
  Grid,
  Button,
  Alert,
  useToast,
  ButtonGroup,
  Icon,
  Text,
  Stack,
} from "@chakra-ui/core";
import { theme } from "../../theme";
import styled from "@emotion/styled";
import { useLoading } from "../../hooks/useLoading";
import { history } from "../../lib/history";
import { FormikField, FormikSelect } from "../shared/forms";
import { NoLeadingOrTrailingSpaces } from "../../lib/schemata";

export const bucketNameSchema = yup.object().shape({
  name: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
});

export const bucketKeySchema = yup.object().shape({
  key: yup.string().required("Required"),
});

const Container = styled.div`
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[100]};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.md};
  padding: 1em;
  margin-bottom: 1em;
`;

const Key = styled.div`
  box-shadow: ${theme.shadows.md};
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[50]};
  padding: 1em;
  background-color: #fff;
  cursor: pointer;
  h3 {
    margin-bottom: 0.25em;
  }

  &:hover {
    background-color: ${theme.colors.gray[50]};
  }
  &:active {
    background-color: ${theme.colors.gray[100]};
  }
`;

const KeyGrid = styled.div`
  display: grid;
  grid-gap: 2em;
  grid-template-columns: 1fr;
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: ${theme.breakpoints[3]}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

const FormGrid = styled(Grid)`
  box-shadow: ${theme.shadows.md};
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[50]};
  padding: 1em;
  display: grid;
  background-color: #fff;
  margin-bottom: 1em;
`;

export interface BucketProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const access = {
  ":read": "Read",
  ":full": "Full",
};

export const Bucket = forwardRef<HTMLDivElement, BucketProps>(
  ({ children, className, ...props }, ref) => {
    const match = useRouteMatch<{ id: string; action?: string }>(
      "/buckets/:id/:action?"
    );
    const [submitting, , withLoading] = useLoading();
    const [bucketSubmitting, , withBucketLoading] = useLoading();
    const toast = useToast();
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const [bucketSubmitError, setBucketSubmitError] = useState<{
      message?: string;
    }>({});
    const [removeKeyConfirmation, setRemoveKeyConfirmation] = useState("");
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [keyLoading, , withKeyLoading] = useLoading();
    const [bucket, loading, reload] = useApiCall(
      () => getBucket(match?.params.id || ""),
      {},
      []
    );
    const [keys] = useApiCall(() => getKeys(), [], []);
    const keyIds = useMemo(() => (bucket.keys || []).map((k) => k.id), [
      bucket.keys,
    ]);

    const handleBucketEdit = useCallback(
      (values) => {
        withBucketLoading(async () => {
          try {
            setBucketSubmitError({});
            await updateBucketDisplayName({
              bucket: bucket.id,
              name: values.name,
            });
            toast({
              title: "Bucket updated.",
              description: "Bucket successfully been updated",
              status: "success",
              duration: 2000,
              position: "top",
              isClosable: true,
            });
            history.push(`/buckets/${bucket.id}`);
            await reload();
          } catch (e) {
            setBucketSubmitError({ message: e.message });
          }
        });
      },
      [bucket.id, reload, toast, withBucketLoading]
    );

    const handleKeyEdit = useCallback(
      (values) => {
        withLoading(async () => {
          try {
            setSubmitError({});
            await addKeyToBucket({
              bucket: match?.params.id || "",
              key: values.key,
            });
            await reload();
            toast({
              title: "Key added",
              status: "success",
              duration: 3000,
              position: "top",
            });
          } catch (e) {
            setSubmitError({ message: e.message });
          }
        });
      },
      [match, reload, toast, withLoading]
    );

    const action = match?.params.action;
    useEffect(() => {
      setIsEditing(action === "edit");
    }, [action]);

    if (!loading.loaded || loading.loading) {
      return <Loader>fetching bucket...</Loader>;
    }
    return (
      <>
        <Container
          {...props}
          ref={ref}
          className={classnames("", {}, className)}
        >
          {bucketSubmitError.message && (
            <Alert status="error" marginBottom="0.5em">
              {bucketSubmitError.message}
            </Alert>
          )}{" "}
          <div data-testid="update-bucket">
            {isEditing ? (
              <Formik
                enableReinitialize
                initialValues={{ name: bucket.tag }}
                onSubmit={handleBucketEdit}
                validationSchema={bucketNameSchema}
              >
                <Form>
                  <Grid templateRows="auto auto" gap={1}>
                    <Stack mb={1}>
                      <FormikField
                        label="Bucket Tag Name"
                        name="name"
                        isRequired
                        placeholder="e.g. App One"
                        inputTestId="bucket-name"
                      />
                    </Stack>
                    <Heading size="sm" color={theme.colors.gray[400]}>
                      {bucket.name}.s3.bigstorage.io
                    </Heading>
                    <Stack alignItems="flex-end" mt={1}>
                      <ButtonGroup display="block" spacing={1}>
                        <Button
                          variant="outline"
                          isDisabled={bucketSubmitting.loading}
                          onClick={() => history.push(`/buckets/${bucket.id}`)}
                          data-testid="cancel-bucket-update"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="solid"
                          isLoading={bucketSubmitting.loading}
                          isDisabled={bucketSubmitting.loading}
                          variantColor="blue"
                          data-testid="confirm-bucket-update"
                        >
                          Update
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  </Grid>
                </Form>
              </Formik>
            ) : (
              <>
                <Grid
                  justifyItems="flex-end"
                  marginBottom="0.7em"
                  templateColumns="auto 1fr"
                  gap={1}
                >
                  <Heading alignSelf="center" size="xl">
                    {bucket.tag}
                  </Heading>
                  <Stack alignSelf="center">
                    <Button
                      data-testid="edit-bucket-tag"
                      onClick={() => history.push(`/buckets/${bucket.id}/edit`)}
                    >
                      <Icon size="0.9em" name="edit" />
                    </Button>
                  </Stack>
                </Grid>
                <Heading size="sm" color={theme.colors.gray[400]}>
                  {bucket.name}.s3.bigstorage.io
                </Heading>
              </>
            )}
          </div>
        </Container>
        {submitError.message && (
          <Alert status="error" marginBottom="0.5em">
            {submitError.message}
          </Alert>
        )}
        <Formik
          initialValues={{ key: "" }}
          onSubmit={handleKeyEdit}
          validationSchema={bucketKeySchema}
        >
          <Form>
            <FormGrid
              alignItems="flex-start"
              gridGap="1em"
              templateColumns="1fr auto"
            >
              <FormikSelect
                name="key"
                label="Key"
                isRequired
                options={keys
                  .filter(
                    (key) => key.name !== "master" && !keyIds.includes(key.id)
                  )
                  .map((key) => ({
                    value: key.id,
                    label: key.name,
                  }))}
              />
              <Button
                isLoading={submitting.loading}
                type="submit"
                variantColor="blue"
                data-testid="add-key-to-bucket"
                mt="1.75em"
              >
                Add key
              </Button>
            </FormGrid>
          </Form>
        </Formik>
        <KeyGrid>
          {bucket.keys.map((key) => (
            <Key>
              <Heading as="h3" size="md">
                {key.name}
              </Heading>
              {removeKeyConfirmation === key.id && (
                <Grid justifyItems="flex-end">
                  <Text fontSize="xs">Are you sure?</Text>
                </Grid>
              )}
              <Grid justifyItems="flex-end" templateColumns="auto 1fr" gap={1}>
                <Heading as="h5" size="sm" color={theme.colors.gray[400]}>
                  {access[key.access]}
                </Heading>
                <span>
                  {removeKeyConfirmation === key.id ? (
                    <ButtonGroup display="block" spacing={1}>
                      <Button
                        data-testid="cancel-delete-bucket-key"
                        variant="outline"
                        size="xs"
                        isDisabled={keyLoading.loading}
                        onClick={() => {
                          setRemoveKeyConfirmation("");
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        data-testid="confirm-delete-bucket-key"
                        variant="solid"
                        isLoading={keyLoading.loading}
                        isDisabled={keyLoading.loading}
                        size="xs"
                        onClick={async () => {
                          setRemoveKeyConfirmation(key.id);
                          withKeyLoading(async () => {
                            setSubmitError({});
                            try {
                              await removeKeyFromBucket({
                                bucket: bucket.id,
                                key: key.id,
                              });

                              await reload();

                              toast({
                                title: "Key removed from Bucket",
                                status: "success",
                                duration: 3000,
                                position: "top",
                              });
                            } catch (e) {
                              setSubmitError({ message: e.message });
                            }
                          });
                        }}
                      >
                        Confirm
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <Button
                      size="xs"
                      data-testid="delete-bucket-key"
                      onClick={() => setRemoveKeyConfirmation(key.id)}
                    >
                      <Icon size="0.9em" name="delete" />
                    </Button>
                  )}
                </span>
              </Grid>
            </Key>
          ))}
        </KeyGrid>
      </>
    );
  }
);
