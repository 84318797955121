import { request } from "./base";

export type AccountTier = "free" | "paid";

export type RedirectThreeDSecure = {
    url: string;
    parameters: {name: string;
                 value: string;}[];
};



export type Account = {
  id: string;
  name: string;
  paymentMethod: string;
  companyNumber: string;
  vatNumber: string;
  address: {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    line5: string;
  };
  tier: AccountTier;
  creditCard: {
    id: string;
    cardholderName: string;
    expiryMonth: number;
    expiryYear: number;
    lastfour: string;
    type: string;
  };
};

export type StatementItem = {
  date: string;
  debit: number;
  credit: number;
  balance: number;
  description: string;
  type: string;
};

export const updateAccountDetails = async (details: any) => {
  return request("/billing/account/detail", {
    method: "POST",
    body: JSON.stringify(details),
  });
};

export const updateAccountWithCC = async (details: any) => {
  return request("/billing/account-with-cc", {
    method: "POST",
    body: JSON.stringify(details),
  });
};

export const account = async (): Promise<{
  account: Account;
  statementItems: StatementItem[];
}> => {
  const response = await request(`/billing/account`, {});
  const {
    data: { account, statementItems },
  } = await response.json();
  return {
    account,
    statementItems,
  };
};

export type CreditCard = {
  cardholderName: string;
  expiryMonth: number;
  expiryYear: number;
  id: string;
  lastfour: string;
  type: string;
};

export const creditCards = async (): Promise<CreditCard[]> => {
  const response = await request("/billing/credit-cards", {});
  const { data } = await response.json();
  return data.creditCards;
};

export const emailStatement = async () => {
  return request("/billing/account-statement", {
    method: "POST",
  });
};

export const deleteCreditCard = async (ccId: string) => {
    const response = await request(`/billing/credit-cards/${ccId}`, {
        method: "DELETE",
    });
    const result = await response.json();
    return result;
};


export const addCreditCard = async (details: any):Promise<RedirectThreeDSecure> => {
    const response = await request("/billing/credit-cards/init-3ds-peach", {
        method: "POST",
        body: JSON.stringify(details),
    });
    const { data } = await response.json();
    console.log(data);
    return data;
};

export const creditCard3dsResponse = async (details:any) => {
    const response = await request("/billing/credit-cards/handle-3ds-result", {
        method: "POST",
        body: JSON.stringify(details),
    });
    const result = await response.json();
    return result;
}
