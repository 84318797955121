import React, {
  forwardRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
  FC,
} from "react";
import classnames from "classnames";
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import * as yup from "yup";
import { useApiCall } from "../../hooks/useApiCall";
import { usePagination } from "../../hooks/usePagination";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import {
  Input,
  Grid,
  Icon,
  useDisclosure,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Alert,
  Button,
  useToast,
  Box,
  ModalHeader,
  ModalFooter,
  Text,
} from "@chakra-ui/core";
import { Loader } from "../shared";
import { Pager } from "../shared/Pager";
import { useQueryParams, NumberParam } from "use-query-params";
import { getKeys, Key, createKey, deleteKey } from "../../lib/api/buckets";
import { Loading, useLoading } from "../../hooks/useLoading";
import { account as apiAccount, Account } from "../../lib/api/billing";
import { Link as RRLink } from "react-router-dom";
import { FormikField, FormikSelect } from "../shared/forms";
import { NoSpaces, OnlyAlphabeticAndHyphens } from "../../lib/schemata";

export interface KeysProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const schema = yup.object().shape({
  name: yup
    .string()
    .required("Required")
    .test(NoSpaces)
    .test(OnlyAlphabeticAndHyphens),
  perms: yup.string().required(),
});

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  margin-top: 1em;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  box-shadow: ${theme.shadows.md};
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[50]};
  padding: 1em;
  cursor: pointer;
  background-color: #fff;
  width: 100%;
`;

const Row = styled.div`
  width: 100%;
  cursor: pointer;
  display: grid;
  background-color: #fff;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
  word-break: break-all;

  justify-content: space-between;
  h3 {
    margin-bottom: 0.25em;
  }
  &:hover {
    background-color: #fafafa;
  }
  &:active {
    background-color: #f8f8f8;
  }

  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Column = styled.div<{ textAlign: "left" | "right" }>`
  display: flex;
  flex-direction: column;
  &:last-child {
    text-align: ${(props) => props.textAlign};
  }
  span:first-child {
    font-weight: bold;
    color: ${theme.colors.gray[500]};
  }
`;

const FormGrid = styled(Grid)`
  box-shadow: ${theme.shadows.md};
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[50]};
  padding: 1em;
  display: grid;
  background-color: #fff;
`;

const DeleteColumn = styled.div`
  display: flex;
  width: 1em;
  color: ${theme.colors.gray[500]};
`;

export const Link = styled(RRLink)`
  color: ${theme.colors.blue[400]};
  &:hover {
    text-decoration: underline;
  }
`;

const perPage = 10;

const perms: { [index: string]: string } = {
  ":read": "Read",
  ":full": "Full",
};

export const Keys = forwardRef<HTMLDivElement, KeysProps>(
  ({ children, className, ...props }, ref) => {
    const [keys, loading, reload] = useApiCall(() => getKeys(), [], []);
    const [search, setSearch] = useState("");
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [keyToDelete, setKeyToDelete] = useState<Partial<Key>>({});
    const [selectedKey, setSelectedKey] = useState<Partial<Key>>({});
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const [formLoading, , withLoading] = useLoading<void>();
    const [account, setAccount] = useState<Partial<Account>>({});
    const [deletingKeyLoading, , withDeletingKeyLoading] = useLoading<void>();

    const [paginator, setPaginator] = useQueryParams({
      page: NumberParam,
      perPage: NumberParam,
    });

    useEffect(() => {
      setPaginator({ page: 1 });
    }, [setPaginator]);

    const loadAccount = useCallback(
      () =>
        withLoading(async () => {
          if (!loading.loading && !loading.loaded) {
            const acc = await apiAccount();
            // setAccount(acc.account);
            setAccount(acc.account);
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const handleSubmit = useCallback(
      (values) => {
        withLoading(async () => {
          try {
            setSubmitError({});
            await createKey({
              ...values,
              perms: values.perms as any,
            });
            await reload();
            toast({
              title: "Key created",
              status: "success",
              duration: 3000,
              position: "top",
            });
          } catch (e) {
            setSubmitError({ message: e.message });
          }
        });
      },
      [reload, toast, withLoading]
    );

    // Wrapped the normal Formik/Yup validation to compare our own (non-form) values here
    const validateForm = useCallback(
      async (values) => {
        if (keys.map((k) => k.name).includes(values.name)) {
          return { name: "Name already taken" };
        }

        try {
          await validateYupSchema(values, schema);
          return {};
        } catch (errors) {
          return yupToFormErrors(errors);
        }
      },
      [keys]
    );

    useEffect(() => {
      loadAccount();
    }, [loadAccount]);

    const filteredKeys = useMemo(() => {
      return keys
        .filter((key) => !key.master)
        .filter((key) =>
          !!search
            ? `${key.name}`
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
            : true
        );
    }, [keys, search]);

    const masterKey =
      useMemo(() => {
        return keys.find((key) => key.master);
      }, [keys]) || {};

    const pagination = usePagination({
      count: filteredKeys.length,
      page: paginator.page || 1,
      rowsPerPage: paginator.perPage || perPage,
      rowsPerPageOptions: [10, 30, 50],
    });

    const handleDeleteKey = useCallback(() => {
      withDeletingKeyLoading(async () => {
        if (!keyToDelete.id) return;
        try {
          setSubmitError({});
          if (!keyToDelete.master) {
            await deleteKey(keyToDelete.id);
          }
          await reload();
          toast({
            title: "Key successfully deleted",
            status: "success",
            duration: 3000,
            position: "top",
          });
        } catch (e) {
          setSubmitError({ message: e.message });
        }
        setKeyToDelete({});
      });
    }, [withDeletingKeyLoading, keyToDelete, reload, toast]);

    if (loading.loading) {
      return <Loader>fetching Keys...</Loader>;
    }
    if (loading.loaded && keys.length === 0) {
      return (
        <Container
          {...props}
          ref={ref}
          className={classnames("", {}, className)}
        >
          <Row>You don't have any Keys yet</Row>
        </Container>
      );
    }

    return (
      <Container {...props} ref={ref} className={classnames("", {}, className)}>
        <div>
          <Grid alignItems={"center"} templateColumns="auto" gap={4}>
            <Input
              placeholder="Search..."
              value={search}
              data-testid="search"
              onChange={(e: any) => setSearch(e.target.value)}
            />
          </Grid>
        </div>
        {submitError.message && (
          <Alert status="error" marginBottom="0.5em">
            {submitError.message}
          </Alert>
        )}
        {account.tier === "free" && keys.length >= 3 && (
          <Alert status="info" marginBottom="0.5em">
            <p>
              You have reached your free tier limit of 2 keys, you can upgrade
              your account by filling in your credit card details&nbsp;
              <Link to="/billing/payment-details">here</Link>.
            </p>
          </Alert>
        )}
        <Formik
          initialValues={{ name: "", perms: "" }}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          <Form>
            <FormGrid
              alignItems="flex-start"
              gridGap="1em"
              templateColumns={["1fr", "1fr 1fr auto"]}
            >
              <FormikField
                name="name"
                label="Name"
                isRequired
                placeholder="e.g. James"
                inputTestId="keyname"
              />
              <FormikSelect
                name="perms"
                options={[
                  { value: "full", label: "Full" },
                  { value: "read", label: "Read" },
                ]}
                label="Permissions"
                isRequired
                id="key-permissions"
              />
              <Box display="flex" justifyContent="flex-end" mt="1.75em">
                <Button
                  isDisabled={account.tier === "free" && keys.length >= 3}
                  isLoading={formLoading.loading}
                  type="submit"
                  variantColor="blue"
                  data-testid="add-key"
                >
                  Add key
                </Button>
              </Box>
            </FormGrid>
          </Form>
        </Formik>
        <KeyRow
          onOpen={onOpen}
          setSelectedKey={setSelectedKey}
          setKeyToDelete={setKeyToDelete}
          i={0}
          keyToDelete={keyToDelete}
          keyPair={masterKey as Key}
          deletingKeyLoading={deletingKeyLoading}
        />
        {filteredKeys.slice(pagination.begin, pagination.end).map((key, i) => (
          <KeyRow
            onOpen={onOpen}
            key={key.id}
            setSelectedKey={setSelectedKey}
            setKeyToDelete={setKeyToDelete}
            i={i + 1}
            keyToDelete={keyToDelete}
            keyPair={key}
            handleDeleteKey={handleDeleteKey}
            deletingKeyLoading={deletingKeyLoading}
          />
        ))}
        {pagination.showPagination && <Pager pagination={pagination} />}
        <Modal isOpen={isOpen} onClose={onClose} data-test-id="keys-modal">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton data-testid="close-key-info" />
            <ModalBody id="keys-modal-body">
              <b>Access key:</b>
              <p data-testid="access-key">{selectedKey.accessKey}</p>
              <b>Secret key:</b>
              <p data-testid="secret-key">{selectedKey.secretKey}</p>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
    );
  }
);

const KeyRow: FC<{
  setSelectedKey: any;
  setKeyToDelete: any;
  keyToDelete: Partial<Key>;
  onOpen: () => void;
  keyPair: Key;
  i: number;
  deletingKeyLoading: Loading;
  handleDeleteKey?: () => void;
}> = ({
  setSelectedKey,
  setKeyToDelete,
  keyToDelete,
  onOpen,
  keyPair: key,
  i,
  deletingKeyLoading,
  handleDeleteKey,
}) => (
  <RowContainer>
    <Row
      onClick={() => {
        setSelectedKey(key);
        onOpen();
      }}
      data-testid={`key-${i}`}
    >
      <Column textAlign="left">
        <span>Name</span>
        <span>{key.name}</span>
      </Column>
      <Column textAlign="left">
        <span>Master</span>
        <span>{key.master ? "Yes" : "No"}</span>
      </Column>
      <Column textAlign="left">
        <span>Permissons</span>
        <span>{perms[key.access]}</span>
      </Column>
      <Column textAlign="left" data-testid={`key-reveal-${i}`}>
        <span>Reveal keys</span>
        <span>
          <Icon name="edit" />
        </span>
      </Column>
    </Row>
    <DeleteColumn
      onClick={() => {
        if (!key.master) {
          setKeyToDelete(key);
        }
      }}
      data-testid={`delete-key-${i}`}
    >
      <Grid justifyItems="flex-end">
        <span>{!key.master && <Icon name="delete" />}</span>
      </Grid>
    </DeleteColumn>
    {handleDeleteKey && (
      <Modal isOpen={!!keyToDelete.id} onClose={() => setKeyToDelete({})}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm key deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={2}>
              Key to be deleted: <b>{keyToDelete.name}</b>
            </Text>
            <Text>
              Deleting this key will mean that you remove its access from all
              associated buckets.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={() => setKeyToDelete({})}
              data-testid="key-delete-cancel"
            >
              Cancel
            </Button>
            <Button
              isDisabled={deletingKeyLoading.loading}
              isLoading={deletingKeyLoading.loading}
              variant="solid"
              variantColor="blue"
              onClick={() => handleDeleteKey()}
              data-testid="key-delete-confirm"
            >
              Confirm Deletion
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )}
  </RowContainer>
);
