import React, { forwardRef } from "react";
import classnames from "classnames";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import { Box, Heading, Grid, Link, Icon } from "@chakra-ui/core";
import { Section } from "../profile/Shared";

export interface PoliciesProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  margin-top: 1em;
`;

export const SectionContainer = styled(Box)`
  display: grid;
  grid-template-areas: "policies";
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-areas: "policies";
  }
  grid-gap: 1em;
`;

export const Policies = forwardRef<HTMLDivElement, PoliciesProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Container {...props} ref={ref} className={classnames("", {}, className)}>
        <Heading as="h3" size="lg">
          Index of Policies
        </Heading>
        <SectionContainer>
          <Section gridArea="policies">
            <Grid padding="0.5em">
              <Link
                isExternal
                  href="https://support.cloudafrica.net/support/solutions/articles/101000465509-acceptable-use-policy"
                target="_blank"
              >
                <Icon name="external-link" mr="0.5em" />
                Acceptable Use Policy
              </Link>
            </Grid>
            <Grid padding="0.5em">
              <Link
                isExternal
                  href="https://support.cloudafrica.net/support/solutions/articles/101000439110-terms-and-conditions"
                target="_blank"
              >
                <Icon name="external-link" mr="0.5em" />
                Terms and Conditions
              </Link>
            </Grid>
            <Grid padding="0.5em">
              <Link
                isExternal
                  href="https://support.cloudafrica.net/support/solutions/articles/101000465532-take-down-procedure"
                target="_blank"
              >
                <Icon name="external-link" mr="0.5em" />
                Take Down Procedure
              </Link>
            </Grid>
            <Grid padding="0.5em">
              <Link
                isExternal
                  href="https://support.cloudafrica.net/support/solutions/articles/101000439109-service-level-agreements"
                target="_blank"
              >
                <Icon name="external-link" mr="0.5em" />
                Service Level Agreement
              </Link>
            </Grid>
            <Grid padding="0.5em">
              <Link
                isExternal
                  href="https://support.cloudafrica.net/support/solutions/articles/101000439108-data-protection-policy"
                target="_blank"
              >
                <Icon name="external-link" mr="0.5em" />
                Data Protection Policy
              </Link>
            </Grid>
            <Grid padding="0.5em">
              <Link
                isExternal
                  href="https://support.cloudafrica.net/support/solutions/articles/101000439117-terms-of-use"
                target="_blank"
              >
                <Icon name="external-link" mr="0.5em" />
                Terms of Use
              </Link>
            </Grid>
          </Section>
        </SectionContainer>
      </Container>
    );
  }
);
