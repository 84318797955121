import React, { forwardRef } from "react";
import classnames from "classnames";
import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { Section } from "../../profile/Shared";
import { Box, Heading, Grid, Link } from "@chakra-ui/core";
import escalationImage from "./escalation.png";

export interface EscalationMatrixProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  margin-top: 1em;
`;

export const SectionContainer = styled(Box)`
  display: grid;
  grid-template-areas: "matrix";
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-areas: "matrix";
  }
  grid-gap: 1em;
`;

export const EscalationMatrix = forwardRef<
  HTMLDivElement,
  EscalationMatrixProps
>(({ children, className, ...props }, ref) => {
  return (
    <Container {...props} ref={ref} className={classnames("", {}, className)}>
      <Heading as="h3" size="lg" paddingTop="0.5em">
        Escalation Matrix
      </Heading>
      <SectionContainer>
        <Section gridArea="matrix">
          <Grid padding={["0", "2em"]}>
            <Link
              isExternal
              href="https://support.cloudafrica.net/support/solutions/articles/101000466979-sla-service-level-agreement"
              target="_blank"
            >
              <img
                alt="Escalation Matrix"
                src={escalationImage}
                style={{ width: "100%" }}
              />
            </Link>
          </Grid>
        </Section>
      </SectionContainer>
    </Container>
  );
});
