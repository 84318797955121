import { request } from "./base";

export type Profile = {
  firstName: string;
  lastName: string;
  secondaryEmail: string;
  billingEmail: string;
  technicalEmail: string;
  address: {
    street: string;
    extra: string;
    city: string;
    province: string;
    postalCode: string;
  };
};

export const updateProfile = async (details: Profile) => {
  return request("/identity/profile/update", {
    method: "POST",
    body: JSON.stringify(details)
  });
};
