import React, { useEffect } from "react";

export const FormValidator = ({
  step,
  validateForm,
  children,
}: {
  step: number;
  validateForm: () => void;
  children: React.ReactNode;
}) => {
  // Revalidates via Formik whenever step or useExistingCard changes
  useEffect(() => {
    validateForm();
  }, [step, validateForm]);

  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/44572
  return (children as unknown) as JSX.Element;
};
