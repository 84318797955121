import React from "react";
import {
  Heading,
    Box,
    Alert,
    AlertIcon,
} from "@chakra-ui/core";

import { Account, CreditCard } from "../../../../lib/api/billing";
import { countries } from "../../../../lib/countries";
import { IInitialFormValues } from "..";
import { useSelectOptions } from "../../../../hooks/useSelectOptions";
import { StepControls } from "../StepControls";
import { history } from "../../../../lib/history";
import { Collapsable } from "./Collapsable";
import { FormikSelect } from "../../../shared/forms";

export const formatCC = (card: CreditCard) =>
  `${card.cardholderName} - ${card.type} - ${card.lastfour}`;

export const useFormOptions = ({ ccs }: { ccs: CreditCard[] }) => {
  const ccTypes = useSelectOptions(() => [
    { value: "VISA", label: "VISA" },
    {
      value: "MASTER",
      label: "Mastercard",
    },
    { value: "AMEX", label: "AMEX" },
  ]);

  const country = useSelectOptions(
    () =>
      countries
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((c) => ({
          value: c.code,
          label: c.name,
        })),
    []
  );

  const creditCards = useSelectOptions(
    () =>
      ccs.map((card) => {
        return {
          value: card.id,
          label: formatCC(card),
        };
      }),
    [ccs]
  );

  const ccMonths = useSelectOptions(
    () =>
      new Array(12).fill(1).map((_, i) => {
        return {
          value: String(i + 1).padStart(2,'0'),
            label: String(i + 1).padStart(2,'0'),
        };
      }),
    []
  );

  const ccYears = useSelectOptions(
    () =>
      new Array(7).fill(1).map((_, i) => {
        const d = new Date();
        d.setFullYear(d.getFullYear() + i);
        const y = +`${d.getFullYear()}`;
        return {
          value: y,
          label: y,
        };
      }),
    []
  );

  return {
    ccTypes,
    country,
    creditCards,
    ccMonths,
    ccYears,
  };
};

export const PaymentInputs = ({
  userAccount,
  ccs,
  values,
  setStep,
  isValid,
}: {
  userAccount: Partial<Account>;
  values: IInitialFormValues;
  ccs: CreditCard[];
  setStep: (step: number) => void;
  isValid: boolean;
}) => {
  const options = useFormOptions({ ccs });

  const isUsingBankTransfer = userAccount.paymentMethod !== ":bank-transfer";

  return (
    <>
      {isUsingBankTransfer && (
        <Box mb={5}>
          <Heading as="h3" mb={3} size="md">
            Credit Card
          </Heading>
          
          <>
              {options.creditCards.all().length === 0 && (
                  <Alert status="info" marginBottom="0.5em">
                      <AlertIcon /> 
                      You don't currently have any Credit Cards added. Please go to Billing and then Credit Cards tab in order to add your first Credit Card.
                  </Alert>
              )}              <FormikSelect<IInitialFormValues>
                  name="cc_existingCard"
                  options={options.creditCards.all()}
                  />
          </>
        </Box>
      )}
      <Box mb={2}>
        <Collapsable
          heading="Monthly Payment Description"
          testId="monthly-payment-description"
        >
          <p>
            Charges are Invoiced to Accounts where Storage exceeds 25GBs, at
            which point Access to the additional space used will also be charged
            for.
          </p>
          {isUsingBankTransfer && (
            <p>
              All Invoices are payable at the end of the month via Credit Card
              during which services were rendered to you.
            </p>
          )}
        </Collapsable>
      </Box>
      <Collapsable
        heading="Account Description"
        testId="account-payment-description"
      >
        <p>
          All Invoices are payable at the end of the month during which services
          were rendered to you.
        </p>
        {isUsingBankTransfer && (
          <p>
            Payment is accepted via a Credit Card linked to your account. For
            this purpose, we will require valid credit card information from
            you.
          </p>
        )}
      </Collapsable>
      <StepControls
        onConfirm={() => setStep(3)}
        onCancel={() => history.push("/dashboard")}
        isValid={isValid}
        isLoading={false}
      />
    </>
  );
};
