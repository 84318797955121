import React, { forwardRef } from "react";
import classnames from "classnames";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { theme } from "../../theme";
import { List as CList, ListItem } from "@chakra-ui/core";
import {
  Database,
  ChevronsDown,
  ChevronsUp,
  Lock,
  HardDrive,
} from "react-feather";
import { history } from "../../lib/history";

export interface TierSelectionProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Container = styled.div``;

const Heading = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
  text-align: center;
`;

const Split = styled.div`
  max-width: 800px;
  margin: auto;
  position: relative;
  display: flex;
  border-radius: ${theme.radii.lg};
  box-shadow: ${theme.shadows.md};
  overflow: hidden;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints[0]}) {
    flex-direction: row;
  }
`;

const SubHeading = styled.h3<{ right?: boolean }>`
  font-size: 1.25em;
  font-weight: bold;
  margin: 0 0.5em 0.5em;
  margin-top: ${(props) => props.right && "0.75em"};

  @media (min-width: ${theme.breakpoints[0]}) {
    margin-top: 0;
  } ;
`;

const circleStyles = css`
  display: block;
  content: "OR";
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -0.75em;
  border-radius: 50%;
  background-color: ${theme.colors.gray[200]};
  padding: 0.5em;
  font-size: 1.25em;
  border: 1px solid ${theme.colors.gray[300]};
  @media (min-width: ${theme.breakpoints[0]}) {
    top: 50%;
    font-size: 1.5em;
    margin-top: 0;
  }
  @media (min-width: ${theme.breakpoints[1]}) {
    font-size: 1.75em;
  }
`;

const Selection = styled.div<{ align: "left" | "right" }>`
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: ${(props) =>
    props.align === "right" ? theme.colors.gray[50] : "#fff"};
  border: solid 1px ${theme.colors.gray[50]};
  cursor: pointer;
  flex: 1;

  &:hover {
    background-color: ${theme.colors.gray[100]};
  }

  &:active {
    background-color: ${theme.colors.gray[100]};
  }

  text-align: center;

  ${(props) =>
    props.align === "right" &&
    css`
      &:before {
        ${circleStyles}
      }
    `}

  ${(props) =>
    props.align === "left"
      ? `border-bottom: solid 1px ${theme.colors.gray[100]};`
      : `border-top: solid 1px ${theme.colors.gray[100]};`}

  @media (min-width: ${theme.breakpoints[0]}) {
    text-align: ${(props) => props.align};
    ${(props) =>
      props.align === "left"
        ? `border-right: solid 1px ${theme.colors.gray[100]}; 
           border-bottom: 0;`
        : `border-left: solid 1px ${theme.colors.gray[100]}; 
           border-top: 0;`}
  }
`;

const List = styled(CList)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
`;

const LI = styled(ListItem)<{ align: "flex-start" | "flex-end" }>`
  display: flex;
  justify-content: ${(props) => props.align};
  align-items: center;
  text-align: left;
  margin: 0.25em 0;
  flex-direction: ${(props) =>
    props.align === "flex-start" ? "row" : "row-reverse"};

  svg {
    margin: 0 0.5em;
  }

  @media (min-width: ${theme.breakpoints[0]}) {
    text-align: inherit;
    flex-direction: row;
  }
`;

export const TierSelection = forwardRef<HTMLDivElement, TierSelectionProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Container {...props} ref={ref} className={classnames("", {}, className)}>
        <Heading>Please select your storage tier to continue</Heading>
        <Split>
          <Selection
            align="left"
            onClick={() => history.push("/orders/first?tier=free")}
          >
            <SubHeading>STARTER</SubHeading>
            <List>
              <LI align="flex-start">
                <Database color="red" />
                <span>25GB Storage</span>
              </LI>
              <LI align="flex-start">
                <ChevronsUp color="orange" />
                <span>Unlimited Ingress</span>
              </LI>
              <LI align="flex-start">
                <ChevronsDown color="orange" />
                <span>1 Mbps Egress</span>
              </LI>
              <LI align="flex-start">
                <HardDrive />
                <span>5 Buckets</span>
              </LI>
              <LI align="flex-start">
                <Lock />
                <span>2 Keys</span>
              </LI>
            </List>
          </Selection>
          <Selection
            align="right"
            onClick={() => history.push("/orders/first?tier=paid")}
          >
            <SubHeading right>STANDARD</SubHeading>
            <List>
              <LI align="flex-end">
                <span>Unlimited Storage (billed per GB over 25GB)</span>
                <Database color="green" />
              </LI>
              <LI align="flex-end">
                <span>Unlimited Ingress</span>
                <ChevronsUp color="green" />
              </LI>
              <LI align="flex-end">
                <span>10 Mbps Egress</span>
                <ChevronsDown color="green" />
              </LI>
              <LI align="flex-end">
                <span>Unlimited Buckets</span>
                <HardDrive color="green" />
              </LI>
              <LI align="flex-end">
                <span>Unlimited Keys</span>
                <Lock color="green" />
              </LI>
            </List>
          </Selection>
        </Split>
      </Container>
    );
  }
);
