import styled from "@emotion/styled";
import { theme } from "../../theme";
import {
  Box,
  Spinner,
  Button,
  ButtonProps,
  TabList as TL,
  Tab as TTab,
} from "@chakra-ui/core";
import { Link as RRLink } from "react-router-dom";
import RSelect from "react-select";
import { FC } from "react";
import React from "react";
import { Form } from "formik";

export const UserFlowPage = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  @media (min-width: ${theme.breakpoints[0]}) {
    align-items: center;
    justify-content: center;
  }
  background-color: ${theme.colors.gray[50]};
`;

export const UserFlowContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${theme.shadows.sm};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray[200]};
  padding: 2em;
  height: 100%;
  width: 100%;
  @media (min-width: ${theme.breakpoints[0]}) {
    width: 400px;
    height: auto;
  }
`;

export const UserFlowForm = styled(Form)`
  text-align: center;
`;

export const Logo = styled.img`
  margin-bottom: 1em;
`;

export const Link = styled(RRLink)`
  color: ${theme.colors.blue[400]};
  &:hover {
    text-decoration: underline;
  }
`;

export const LinkButton = styled.button`
  color: ${theme.colors.blue[400]};
  &:hover {
    text-decoration: underline;
  }
  border: none;
  background-color: none;
`;

export const ButtonLink: FC<ButtonProps & { to: string }> = ({
  to,
  ...props
}) => {
  const B: any = Button;
  return <B {...props} as={RRLink} to={to} />;
};

export const Select = styled(RSelect)`
  width: 100%;
  text-align: left;
  border: solid 1px ${theme.colors.gray[50]};
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
`;

export const Loader: FC = ({ children }) => {
  return (
    <LoadingArea>
      <Spinner size="xl" color="blue.500" />
      <span>{children}</span>
    </LoadingArea>
  );
};

export const TabList = styled(TL)`
  align-items: stretch;
  background-color: #fff;
  border-top-left-radius: ${theme.radii.lg};
  border-top-right-radius: ${theme.radii.lg};
  box-shadow: ${theme.shadows.md};
`;

export const Tab = styled(TTab)`
  height: auto;
`;
