import { Box, Stack } from "@chakra-ui/core";
import styled from "@emotion/styled";
import css from "@emotion/css";
import { theme } from "../../theme";

export const Section = styled(Stack)`
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[100]};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.md};
  padding: 1em;
`;

export const Container = styled.div<{ withMargins?: boolean }>`
  border-radius: ${theme.radii.lg};

  ${(props) =>
    props.withMargins &&
    css`
      margin-left: auto;
      margin-right: auto;
      width: 100%;

      @media (min-width: ${theme.breakpoints[1]}) {
        width: 80%;
      }
    `}
`;

export const SectionContainer = styled(Box)`
  display: grid;
  grid-template-areas:
    "basic"
    "address"
    "contact"
    "button";
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-areas:
      "basic contact"
      "address address"
      "button button";
  }
  grid-gap: 1em;
`;
