import React, { useMemo } from "react";
import { Stack, Heading, Grid, Text, Checkbox } from "@chakra-ui/core";

import { CreditCard } from "../../../../lib/api/billing";
import { IInitialFormValues } from "../";
import { history } from "../../../../lib/history";
import { StepControls } from "../StepControls";
import { formatCC } from "./Payment";

export const ConfirmForm = ({
  values,
  formConfirmed,
  setFormConfirmed,
  isLoading,
  ccs,
}: {
  values: IInitialFormValues;
  formConfirmed: boolean;
  setFormConfirmed: (v: boolean) => void;
  isLoading: boolean;
  ccs: CreditCard[];
}) => {
  const accountText = useMemo(() => {
    if (values.tier === "free") return "N/A";

      const creditCard = ccs.find((c) => c.id === values.cc_existingCard);

      return creditCard ? formatCC(creditCard) : "N/A";
      
  }, [values, ccs]);

  return (
    <>
      <Stack marginBottom="1em">
        <Heading as="h5" size="sm">
          Confirm the order summary before you continue. If any information is
          incorrect, please navigate back to the relevant section, to change the
          details.
        </Heading>
      </Stack>
      <Grid templateColumns="8em auto" gap={6}>
        <Heading lineHeight="unset" as="h6" size="xs">
          Tag Name:
        </Heading>
        <Text>{values.tagName}</Text>
      </Grid>
      <Grid templateColumns="8em auto" gap={6}>
        <Heading lineHeight="unset" as="h6" size="xs">
          Tier:
        </Heading>
        <div>{values.tier === "paid" ? "Paid" : "Free"}</div>
      </Grid>
      <Grid templateColumns="8em auto" gap={6}>
        <Heading lineHeight="unset" as="h6" size="xs">
          Payment Interval:
        </Heading>
        <div>{values.tier === "paid" ? "Monthly" : "None"}</div>
      </Grid>
      <Grid templateColumns="8em auto" gap={6}>
        <Heading lineHeight="unset" as="h6" size="xs">
          Account Name:
        </Heading>
        <div>{accountText}</div>
      </Grid>
      <Stack marginTop="2em">
        <Checkbox
          isChecked={formConfirmed}
          onChange={(e) => {
            setFormConfirmed(e.target.checked);
          }}
          data-testid="i-agree"
        >
          I agree that the Summary is Correct, and wish to continue with
          creation of the Bucket with this information
        </Checkbox>
      </Stack>
      <StepControls
        isSubmit
        onCancel={() => history.push("/dashboard")}
        isValid={formConfirmed}
        isLoading={isLoading}
      />
    </>
  );
};
