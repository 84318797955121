import React, { forwardRef, useCallback, useState } from "react";
import classnames from "classnames";
import {
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Stack,
  Button,
  Heading,
  Alert,
  useToast,
} from "@chakra-ui/core";
import { Formik } from "formik";
import logo from "../../logo.svg";
import { history } from "../../lib/history";
import { useLoading } from "../../hooks/useLoading";
import { confrimRegistration, resendRegistrationOtp } from "../../lib/api/user";
import { ValidationError } from "../../lib/api/base";
import { useQueryParam, StringParam } from "use-query-params";
import {
  UserFlowPage,
  UserFlowContainer,
  Logo,
  LinkButton,
  UserFlowForm,
} from "../shared";
import { FormikField } from "../shared/forms";
import { otpSchema } from "./schema";

export interface OtpProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

interface IInitialFormValues {
  otpEmail: string;
}

const initialFormValues: IInitialFormValues = {
  otpEmail: "",
};

export const OTP = forwardRef<HTMLDivElement, OtpProps>(
  ({ children, className, ...props }, ref) => {
    const [email] = useQueryParam("email", StringParam);
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const toast = useToast();
    const [loading, , withLoading] = useLoading<void>();

    const handleSumbit = useCallback(
      (values: IInitialFormValues) => {
        withLoading(async () => {
          try {
            setSubmitError({});
            await confrimRegistration({
              ...values,
              email: email || "",
            });
            toast({
              title: "Account created.",
              description:
                "Your account has successfully been created, please log in",
              status: "success",
              duration: 2000,
              position: "top",
              isClosable: true,
            });
            history.push("/login");
          } catch (e) {
            if (e instanceof ValidationError) {
              return setSubmitError({ message: e.message });
            }
            return setSubmitError({
              message: "An unexpected error has occurred",
            });
          }
        });
      },
      [email, toast, withLoading]
    );

    return (
      <UserFlowPage
        {...props}
        ref={ref}
        className={classnames("", {}, className)}
      >
        <UserFlowContainer rounded="lg">
          <Formik
            initialValues={initialFormValues}
            onSubmit={handleSumbit}
            validationSchema={otpSchema}
          >
            <UserFlowForm>
              <Stack spacing={4}>
                <Logo src={logo} />
                <Heading size="lg">OTP confirmation</Heading>
                {submitError.message && (
                  <Alert status="error">{submitError.message}</Alert>
                )}
                <FormikField<IInitialFormValues> name="otpEmail">
                  {({ field }) => (
                    <InputGroup>
                      <InputLeftElement>
                        <Icon name="lock" />
                      </InputLeftElement>
                      <Input {...field} placeholder="Email OTP" />
                    </InputGroup>
                  )}
                </FormikField>
                <span>
                  An OTP has been sent to the email provided in the previous
                  step. It is valid for 2 hours, after which it will expire.
                  Enter this OTP above, to complete registration{" "}
                </span>
                <LinkButton
                  type="button"
                  onClick={async () => {
                    await resendRegistrationOtp({ email: email || "" });
                    toast({
                      title: "OTP Resent",
                      status: "success",
                      duration: 3000,
                      position: "top",
                    });
                  }}
                >
                  Resend OTP
                </LinkButton>
                <br />
                <Button
                  type="submit"
                  isLoading={loading.loading}
                  variantColor="yellow"
                >
                  Confirm
                </Button>
              </Stack>
            </UserFlowForm>
          </Formik>
        </UserFlowContainer>
      </UserFlowPage>
    );
  }
);
