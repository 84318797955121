import React from "react";
import {
  Stack,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Radio,
  Text,
  RadioGroup,
} from "@chakra-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import { IInitialFormValues } from "..";
import { StepControls } from "../StepControls";
import { history } from "../../../../lib/history";
import styled from "@emotion/styled";
import { theme } from "../../../../theme";
import { Account } from "../../../../lib/api/billing";
import { FormikField } from "../../../shared/forms";

const StorageTiersGrid = styled.div`
  display: grid;
  grid-gap: 1.5em;
  margin-bottom: 0.5em;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FreeTierInfo = () => (
  <>
    <p>1. 25 GB Storage Space</p>
    <p>2. Unlimited ingress</p>
    <p>3. 1 Mbps egress</p>
    <p>4. 5 Buckets</p>
    <p>5. 3 Access Keys</p>
  </>
);

const PaidTierInfo = () => (
  <>
    <p>
      1. The first 25 GB Storage Space is free after which all space used is
      charged per GB at the end of each month.
    </p>
    <p>2. Unlimited ingress</p>
    <p>3. 10 Mbps egress</p>
    <p>4. Unlimited Buckets</p>
    <p>5. Unlimited Keys</p>
  </>
);

export const GetStartedForm = ({
  values,
  errors,
  touched,
  setStep,
  isValid,
  setFieldValue,
  userAccount,
}: {
  values: IInitialFormValues;
  errors: FormikErrors<IInitialFormValues>;
  touched: FormikTouched<IInitialFormValues>;
  userAccount: Partial<Account>;
  setStep: (step: number) => void;
  isValid: boolean;
  setFieldValue: any;
}) => {
  const onConfirm = () => {
    if (
      values.tier === "free" ||
      userAccount.paymentMethod === ":bank-transfer"
    ) {
      setStep(3);
    } else {
      setStep(2);
    }
  };

  return (
    <>
      <Heading as="h3" mb={3} size="md">
        Choose a bucket name
      </Heading>
      <Text data-testid="generated-message">
        Each auto generated Bucket Name will be unique on the BigStorage Domain.
        Please choose your own Tag Name, which you can use to easily identify
        your Bucket in this application.
      </Text>
      <FormikField<IInitialFormValues>
        mt={3}
        label="Bucket Tag Name"
        name="tagName"
        isRequired
        placeholder="e.g. App One"
        data-testid="bucket-name"
      />
      <Stack mt={6} mb={3}>
        {userAccount.tier === "free" ? (
          <>
            <StorageTiersGrid>
              <Stack color="#4A5568">
                <Heading as="h5" size="sm">
                  You are currently registered for the Free Storage Tier, which
                  includes the following features:
                </Heading>
                <FreeTierInfo />
              </Stack>
              <Stack color="#4A5568">
                <Heading as="h5" size="sm">
                  What does the Paid Storage Tier offer?
                </Heading>
                <PaidTierInfo />
              </Stack>
            </StorageTiersGrid>
            <FormControl
              mt={3}
              isRequired
              isInvalid={touched.tier && !!errors.tier}
            >
              <FormLabel htmlFor="tier" fontWeight="bold">
                Do you want to continue using the Free Tier?
              </FormLabel>
              <RadioGroup
                mt={2}
                defaultValue={values.tier}
                value={values.tier}
                onChange={(e) => setFieldValue("tier", e.target.value)}
              >
                <Radio
                  data-testid="free-teir-radio"
                  value="free"
                  mr={["0", "0", "1em"]}
                >
                  Yes, Continue on Free Tier
                </Radio>
                <Radio value="paid" data-testid="paid-teir-radio">
                  No, Change to Paid Tier. Valid Credit Card Required
                </Radio>
              </RadioGroup>
              <FormErrorMessage>{errors.tier}</FormErrorMessage>
            </FormControl>
          </>
        ) : (
          <Stack color="#4A5568" marginBottom="1em">
            <Heading as="h5" size="sm">
              You are currently registered for the Paid Storage Tier, which
              includes the following features:
            </Heading>
            <PaidTierInfo />
          </Stack>
        )}
      </Stack>
      <StepControls
        onConfirm={onConfirm}
        onCancel={() => history.push("/dashboard")}
        isValid={isValid}
        isLoading={false}
      />
    </>
  );
};
