import * as yup from "yup";

const isString = (v: unknown): v is string => typeof v === "string";

const isEmpty = (v: string) => v.length === 0;

export const NoLeadingOrTrailingSpaces: yup.TestOptions = {
  name: "no-leading-trailing-spaces",
  message: "No leading or trailing spaces",
  test: (v: unknown) => {
    if (isString(v) && !isEmpty(v) && /^\s+|\s+$/.test(v)) return false;
    return true;
  },
};

export const NoCountryCode: yup.TestOptions = {
  name: "no-country-code",
  message: "May not contain country code",
  test: (v: unknown) => {
    if (isString(v) && !isEmpty(v) && v.includes("+")) return false;
    return true;
  },
};

export const OnlyNumbers: yup.TestOptions = {
  name: "only-numbers",
  message: "Only numbers are allowed",
  test: (v: unknown) => {
    if (isString(v) && !isEmpty(v) && /\D/.test(v as string)) return false;
    return true;
  },
};

export const ValidEmail: yup.TestOptions = {
  name: "valid-email",
  message: "Must be a valid email",
  test: (v: unknown) => {
    if (isString(v) && !isEmpty(v) && !v.includes("@")) return false;
    return true;
  },
};

export const NoSpaces: yup.TestOptions = {
  name: "no-spaces",
  message: "No spaces allowed",
  test: (v: unknown) => {
    if (isString(v) && !isEmpty(v) && /\s/.test(v)) return false;
    return true;
  },
};

export const OnlyAlphabeticAndHyphens: yup.TestOptions = {
  name: "only-alphabetic-and-hyphens",
  message: "Only alphabetic characters and hyphens allowed",
  test: (v: unknown) => {
    if (isString(v) && !isEmpty(v) && !/^([a-z])[a-z-]*$/i.test(v))
      return false;
    return true;
  },
};

export const PasswordValidation = yup
  .string()
  .required("Password is required")
  .test(NoLeadingOrTrailingSpaces)
  .min(8, "Password needs to be at least 8 characters")
  .matches(/[a-z]/, "Password needs at least 1 lowercase letter")
  .matches(/[A-Z]/, "Password needs at least 1 uppercase letter")
  .matches(/[0-9]/, "Password needs at least 1 numeric character");

export const addressValidators = {
  street: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
  extra: yup.string().test(NoLeadingOrTrailingSpaces),
  city: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
  province: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
  postalCode: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
};

export const cellValidator = yup
  .string()
  .required("Required")
  .min(5, "Must be a valid cell number (at least 5 numbers)")
  .test(NoLeadingOrTrailingSpaces)
  .test(NoCountryCode)
  .test(OnlyNumbers);

export const creditCardValidators = {
  cc_type: yup.string().required("Required"),
  cc_name: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
  cc_number: yup
    .string()
    .required("Required")
    .trim()
    .min(15, "Must be at least 15 numbers")
    .max(16, "Must be at most 16 numbers")
    .test(OnlyNumbers),
  cc_expMonth: yup.number().nullable().required("Required"),
  cc_expYear: yup.number().nullable().required("Required"),
  cc_cvv: yup
    .string()
    .required("Required")
    .trim()
    .min(3, "Must be at least 3 numbers")
    .max(4, "Must be at most 4 numbers")
    .test(OnlyNumbers),
};
