import React, {
  forwardRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import classnames from "classnames";
import {
  Heading,
  Stack,
  Button,
  useToast,
  Box,
  Alert,
  Grid,
} from "@chakra-ui/core";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { OrderWizard } from "../../order-wizard";
import { useLoading } from "../../../hooks/useLoading";
import { Container, Section } from "../../profile/Shared";
import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { Loader } from "../../shared";
import { history } from "../../../lib/history";
import { ValidationError, NotAuthenticatedError } from "../../../lib/api/base";
import {
  Account,
  creditCards,
  account,
  StatementItem,
  emailStatement,
  updateAccountDetails,
} from "../../../lib/api/billing";
import { useApiCall } from "../../../hooks/useApiCall";
import { lightFormat } from "date-fns";
import { FormikField } from "../../shared/forms";

import {
  NoLeadingOrTrailingSpaces,
  addressValidators,
} from "../../../lib/schemata";

const schema = yup.object().shape({
  name: yup.string().required("Required").test(NoLeadingOrTrailingSpaces),
  regNumber: yup.string(),
  vatNumber: yup.string(),
  ...addressValidators,
});

export interface ManageAccountProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const SectionContainer = styled(Box)`
  display: grid;
  grid-template-areas:
    "basic"
    "address"
    "button";
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-areas:
      "basic address"
      "button";
  }
  grid-gap: 1em;
`;

const TierContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  margin-top: 1.5em;
`;

interface IInitialFormValues {
  name: string;
  regNumber: string;
  vatNumber: string;
  street: string;
  extra: string;
  city: string;
  province: string;
  postalCode: string;
}

const useInitialFormValues = (userAccount: Partial<Account>) => {
  return useMemo(
    () => ({
      name: userAccount.name || "",
      regNumber: userAccount.companyNumber || "",
      vatNumber: userAccount.vatNumber || "",
      street: userAccount.address?.line1 || "",
      extra: userAccount.address?.line2 || "",
      city: userAccount.address?.line3 || "",
      province: userAccount.address?.line4 || "",
      postalCode: userAccount.address?.line5 || "",
    }),
    [userAccount]
  );
};

export const ManageAccount = forwardRef<HTMLDivElement, ManageAccountProps>(
  ({ children, className, ...props }, ref) => {
    const [ccs, , reload] = useApiCall(() => creditCards(), [], []);
    const [accloading, , withAccLoading] = useLoading();
    const [userAccount, setUserAccount] = useState<Partial<Account>>({});
    const [userStatements, setUserStatements] = useState<
      Partial<StatementItem>[]
    >([]);
    const [hasAccount, setHasAccount] = useState(false);
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const [loading, , withLoading] = useLoading<void>();
    const toast = useToast();

    const initialFormValues = useInitialFormValues(userAccount);

    const loadAccount = useCallback(
      () =>
        withAccLoading(async () => {
          if (!accloading.loading && !accloading.loaded) {
            try {
              const acc = await account();
              setUserAccount(acc.account);
              setUserStatements(acc.statementItems);
              // setUserStatements([
              //   {
              //     date: "Mon Jun 01 02:00:00 SAST 2015",
              //     debit: 10000,
              //     credit: 0,
              //     balance: -10000,
              //     description: "INV-0448",
              //     type: "Invoice",
              //   },
              //   {
              //     date: "Mon Jun 01 02:00:00 SAST 2015",
              //     debit: 0,
              //     credit: 2000,
              //     balance: -8000,
              //     description: "RCP0000773",
              //     type: "Customer Receipt",
              //   },
              // ]);
              setHasAccount(true);
            } catch (e) {
              if (e instanceof NotAuthenticatedError) {
                setHasAccount(false);
              }
            }
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const handleSubmit = useCallback(
      (values: IInitialFormValues) => {
        withLoading(async () => {
          try {
            setSubmitError({});

            const submitVals = {
              id: userAccount.id,
              name: values.name,
              companyNumber: values.regNumber,
              vatNumber: values.vatNumber,
              address: {
                line1: values.street,
                line2: values.extra,
                line3: values.city,
                line4: values.province,
                line5: values.postalCode,
              },
            };

            await updateAccountDetails({
              ...submitVals,
            });

            await reload();

            toast({
              title: "Account updated.",
              description: "Your account has successfully been updated",
              status: "success",
              duration: 2000,
              position: "top",
              isClosable: true,
            });
          } catch (e) {
            if (e instanceof ValidationError) {
              return setSubmitError({ message: e.message });
            }
            if (e instanceof NotAuthenticatedError) {
              toast({
                title: "Session expired",
                status: "error",
                duration: 3000,
                position: "top",
              });
              return history.push("/login");
            }
            return setSubmitError({
              message: "An unexpected error has occurred",
            });
          }
        });
      },
      [userAccount, reload, toast, withLoading]
    );

    useEffect(() => {
      loadAccount();
    }, [loadAccount, ccs]);

    if (accloading.loading) {
      return <Loader>fetching account...</Loader>;
    }

    if (!hasAccount) {
      return (
        <TierContainer>
          <OrderWizard />
        </TierContainer>
      );
    }

    return (
      <Container {...props} ref={ref} className={classnames("", {}, className)}>
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          <Form>
            <Heading as="h3" size="lg" paddingBottom="0.5em" paddingTop="0.5em">
              Update your account details (You are on the{" "}
              {userAccount.tier === "paid" ? "Paid" : "Free"} tier)
            </Heading>
            {submitError.message && (
              <Alert status="error" marginBottom="0.5em">
                {submitError.message}
              </Alert>
            )}
            <SectionContainer>
              <Section gridArea="basic">
                <Heading as="h3" size="md" paddingBottom="0.25em">
                  Basic information
                </Heading>
                <FormikField
                  name="name"
                  label="Account Name"
                  isRequired
                  placeholder="e.g. Billing 1"
                />
                <FormikField
                  name="regNumber"
                  label="Company Registration Number"
                  placeholder="e.g. 3123AS21"
                />
                <FormikField
                  name="vatNumber"
                  label="Company Vat Number"
                  placeholder="e.g. 31233124"
                />
              </Section>
              <Section gridArea="address">
                <Heading as="h3" size="md" paddingBottom="0.25em">
                  Address
                </Heading>
                <Stack>
                  <FormikField<IInitialFormValues>
                    name="street"
                    label="Street Address"
                    isRequired
                    placeholder="e.g. 123 Willoughby way"
                  />
                  <FormikField<IInitialFormValues>
                    name="extra"
                    label="Extra detail"
                    placeholder="e.g. Apartment 203"
                  />
                  <FormikField<IInitialFormValues>
                    name="city"
                    label="City"
                    isRequired
                    placeholder="e.g. Cape Town"
                  />
                  <FormikField<IInitialFormValues>
                    name="province"
                    label="Province"
                    isRequired
                    placeholder="e.g. Western Cape"
                  />
                  <FormikField<IInitialFormValues>
                    name="postalCode"
                    label="Postal Code"
                    isRequired
                    placeholder="e.g. 8001"
                  />
                </Stack>
              </Section>
              <Stack gridArea="button" alignItems="center">
                <Button
                  type="submit"
                  isLoading={loading.loading}
                  variantColor="blue"
                >
                  Update
                </Button>
              </Stack>
            </SectionContainer>
          </Form>
        </Formik>
        {userStatements && userStatements.length !== 0 && (
          <Statement statementItems={userStatements} />
        )}
      </Container>
    );
  }
);

export interface StatementProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  statementItems: Partial<StatementItem>[];
}

const StatementContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  margin-top: 1em;
`;

export const Row = styled.div`
  box-shadow: ${theme.shadows.md};
  border-radius: ${theme.radii.lg};
  border: solid 1px ${theme.colors.gray[50]};
  padding: 1em;
  cursor: pointer;
  display: grid;
  background-color: #fff;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  h3 {
    margin-bottom: 0.25em;
  }
  &:hover {
    background-color: #fafafa;
  }
  &:active {
    background-color: #f8f8f8;
  }
`;

export const Column = styled.div<{ textAlign: "left" | "right" }>`
  display: flex;
  flex-direction: column;
  &:last-child {
    text-align: ${(props) => props.textAlign};
  }
  span:first-child {
    font-weight: bold;
    color: ${theme.colors.gray[500]};
  }
`;

export const Statement = forwardRef<HTMLDivElement, StatementProps>(
  ({ statementItems, children, className, ...props }, ref) => {
    const toast = useToast();
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const [loading, , withLoading] = useLoading<void>();

    return (
      <>
        <Heading as="h3" size="lg" paddingBottom="0.5em" paddingTop="0.5em">
          Account Statement
        </Heading>
        {submitError.message && (
          <Alert status="error" marginBottom="0.5em">
            {submitError.message}
          </Alert>
        )}
        <StatementContainer>
          {statementItems.map((item, i) => (
            <Row key={i}>
              <Column textAlign="left">
                <span>Date</span>
                <span>
                  {item.date &&
                    lightFormat(
                      new Date((item.date || "").replace(/[A-Z]{4,}\s/, "")),
                      "yyyy-MM-dd"
                    )}
                </span>
              </Column>
              <Column textAlign="left">
                <span>Description</span>
                <span>{item.description}</span>
              </Column>
              <Column textAlign="left">
                <span>Type</span>
                <span>{item.type}</span>
              </Column>
              <Column textAlign="right">
                <span>Debit</span>
                <span>
                  {((item.debit || 0) / 100).toLocaleString("en-US", {
                    style: "currency",
                    currency: "ZAR",
                  })}
                </span>
              </Column>
              <Column textAlign="right">
                <span>Credit</span>
                <span>
                  {((item.credit || 0) / 100).toLocaleString("en-US", {
                    style: "currency",
                    currency: "ZAR",
                  })}
                </span>
              </Column>
              <Column textAlign="right">
                <span>Balance</span>
                <span>
                  {((item.balance || 0) / 100).toLocaleString("en-US", {
                    style: "currency",
                    currency: "ZAR",
                  })}
                </span>
              </Column>
            </Row>
          ))}
          <Grid>
            <Stack alignItems="center">
              <Button
                variantColor="blue"
                isDisabled={loading.loading}
                isLoading={loading.loading}
                onClick={async () => {
                  withLoading(async () => {
                    try {
                      setSubmitError({});
                      await emailStatement();
                      toast({
                        title: "Statement Successfully Emailed.",
                        description:
                          "Statement Successfully Emailed. please check your mailbox",
                        status: "success",
                        duration: 2000,
                        position: "top",
                        isClosable: true,
                      });
                    } catch (e) {
                      setSubmitError({ message: e.message });
                    }
                  });
                }}
              >
                Email Statement
              </Button>
            </Stack>
          </Grid>
        </StatementContainer>
      </>
    );
  }
);
