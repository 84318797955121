import React, { forwardRef, useCallback, useState } from "react";
import classnames from "classnames";
import {
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Stack,
  Button,
  Heading,
  Alert,
  Link as CLink,
} from "@chakra-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import logo from "../../logo.svg";
import { history } from "../../lib/history";
import { useLoading } from "../../hooks/useLoading";
import { register as apiRegister } from "../../lib/api/user";
import { ValidationError } from "../../lib/api/base";
import {
  UserFlowPage,
  UserFlowContainer,
  Logo,
  Link,
  UserFlowForm,
} from "../shared";
import styled from "@emotion/styled";
import { Check } from "react-feather";
import { FormikField } from "../shared/forms";
import {
  ValidEmail,
  NoLeadingOrTrailingSpaces,
  PasswordValidation,
} from "../../lib/schemata";

export interface RegisterProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email address is required")
    .test(ValidEmail)
    .test(NoLeadingOrTrailingSpaces),
  password: PasswordValidation,
});

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  &:first-child {
    margin-right: 10px;
  }
  li {
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.5em;
      color: green;
    }
  }
`;

const Lists = styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  border-top: #c0c0c0 1px solid;
  border-bottom: #c0c0c0 1px solid;
  margin-bottom: 1em;
`;

const initialFormValues = {
  email: "",
  password: "",
};

interface IInitialFormValues {
  email: string;
  password: string;
}

export const Register = forwardRef<HTMLDivElement, RegisterProps>(
  ({ children, className, ...props }, ref) => {
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const [loading, , withLoading] = useLoading<void>();

    const handleSubmit = useCallback(
      (values: IInitialFormValues) => {
        withLoading(async () => {
          try {
            setSubmitError({});
            await apiRegister({
              ...values,
              email: values.email.toLocaleLowerCase(),
            });
            history.push(`/otp?email=${encodeURIComponent(values.email)}`);
          } catch (e) {
            if (e instanceof ValidationError) {
              return setSubmitError({ message: e.message });
            }
            return setSubmitError({
              message: "An unexpected error has occurred",
            });
          }
        });
      },
      [withLoading]
    );

    return (
      <UserFlowPage
        {...props}
        ref={ref}
        className={classnames("", {}, className)}
      >
        <UserFlowContainer rounded="lg">
          <Formik
            initialValues={initialFormValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            <UserFlowForm>
              <Stack spacing={4}>
                <Logo src={logo} />
                <Heading size="lg">Register for BigStorage</Heading>
                <Lists>
                  <List>
                    <li>
                      <b>Free Tier</b>
                    </li>
                    <li>
                      <Check /> First 25 GB free
                    </li>
                    <li>
                      <Check /> No credit card
                    </li>
                    <li>
                      <Check /> 1 Mbps Egress
                    </li>
                  </List>
                  <List>
                    <li>
                      <b>Paid Tier</b>
                    </li>
                    <li>
                      <Check /> Unlimited storage
                    </li>
                    <li>
                      <Check /> Unlimited keys
                    </li>
                    <li>
                      <Check /> 10 Mbps Egress
                    </li>
                  </List>
                </Lists>
                {submitError.message && (
                  <Alert status="error">{submitError.message}</Alert>
                )}
                <FormikField<IInitialFormValues> name="email">
                  {({ field }) => (
                    <InputGroup>
                      <InputLeftElement>
                        <Icon name="email" />
                      </InputLeftElement>
                      <Input {...field} type="email" placeholder="email" />
                    </InputGroup>
                  )}
                </FormikField>
                <FormikField<IInitialFormValues> name="password">
                  {({ field }) => (
                    <InputGroup>
                      <InputLeftElement>
                        <Icon name="lock" />
                      </InputLeftElement>
                      <Input
                        {...field}
                        type="password"
                        placeholder="password"
                      />
                    </InputGroup>
                  )}
                </FormikField>
                <Button
                  data-testid="register-button"
                  type="submit"
                  isLoading={loading.loading}
                  variantColor="yellow"
                >
                  Register
                </Button>
                <span>
                  By registering for this site, you accept the terms as set out
                  in the{" "}
                  <CLink
                    isExternal
                    color="blue.400"
                    href="https://support.cloudafrica.net/support/solutions/articles/101000439117-terms-of-use"
                    target="_blank"
                  >
                    Terms Of Use Policy
                  </CLink>
                </span>
                <span>
                  Already have an account <Link to="/login">login here</Link>
                </span>
              </Stack>
            </UserFlowForm>
          </Formik>
        </UserFlowContainer>
      </UserFlowPage>
    );
  }
);
