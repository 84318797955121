import { useLocation } from "react-router";

import { useScrollToTop } from "./hooks/useScrollToTop";

export const ScrollToTopOnNavigation = () => {
  const { pathname } = useLocation();

  useScrollToTop([pathname]);

  return null;
};
