import { useMemo } from "react";

interface UsePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
}

export const usePagination = <T>({
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions
}: UsePaginationProps) => {
  const pageCount = useMemo(() => {
    return Math.ceil(count / rowsPerPage);
  }, [count, rowsPerPage]);

  const pages = useMemo(() => {
    const value = Array.from(new Array(pageCount), (_, k) => k + 1);

    if (page < 3) {
      return value.slice(0, 5);
    }

    if (pageCount - page < 3) {
      return value.slice(-5);
    }

    return value.slice(page - 3, page + 2);
  }, [page, pageCount]);

  const showFirst = useMemo(() => {
    return page > 3;
  }, [page]);

  const begin = useMemo(() => {
    return page * rowsPerPage - rowsPerPage;
  }, [page, rowsPerPage]);

  const end = useMemo(() => {
    return page * rowsPerPage;
  }, [page, rowsPerPage]);

  const showNext = useMemo(() => {
    return pageCount - page > 0;
  }, [page, pageCount]);

  const showLast = useMemo(() => {
    return pageCount - page > 2;
  }, [page, pageCount]);

  const showPages = useMemo(() => {
    return pages.length !== 1;
  }, [pages.length]);

  const showPagination = useMemo(() => {
    return count >= Math.min(...rowsPerPageOptions);
  }, [count, rowsPerPageOptions]);

  const showPrevious = useMemo(() => {
    return page > 1;
  }, [page]);

  const lastPageNumber = useMemo(() => {
    return Math.ceil(count / rowsPerPage);
  }, [count, rowsPerPage]);

  return {
    pages,
    showFirst,
    showNext,
    showLast,
    showPages,
    showPagination,
    showPrevious,
    begin,
    end,
    lastPageNumber
  };
};

export default usePagination;
