import { useState, useCallback } from "react";
import { useToast } from "@chakra-ui/core";
import { NotAuthenticatedError } from "../lib/api/base";
import { history } from "../lib/history";

export type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

export const useLoading = <T>(
  inital: Loading = {
    loading: false,
    loaded: false,
    error: undefined,
  }
): [
  Loading,
  (loading: Partial<Loading>) => void,
  (work: () => Promise<T>) => Promise<T | void>
] => {
  const toast = useToast();
  const [loading, setLoading] = useState(inital);

  const load = useCallback(
    (newLoading: Partial<Loading>) => {
      setLoading({ ...loading, ...newLoading });
    },
    [loading]
  );

  const withLoading = useCallback(
    async (work: () => Promise<T>) => {
      try {
        load({ loading: true });
        const result = await work();
        load({ loading: false, loaded: true });
        return result;
      } catch (e) {
        if (e instanceof NotAuthenticatedError) {
          history.push("/login");
          toast({
            title: "Session expired",
            status: "error",
            duration: 3000,
            position: "top",
          });
        }
        load({ loading: false, error: e });
      }
    },
    [load, toast]
  );

  return [loading, load, withLoading];
};
