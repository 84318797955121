import React, { forwardRef, useContext, useState } from "react";
import classnames from "classnames";
import styled from "@emotion/styled";
import css from "@emotion/css";
import { theme } from "../theme";
import logo from "../logo-big.svg";
import ispaLogo from "../ispa.png";
import caLogo from "../ca-logo.png";
import {
  Icon,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Alert,
  AlertIcon,
  Drawer,
  DrawerContent,
  Stack,
  Link as CLink,
  Box,
} from "@chakra-ui/core";
import { Link } from "../pages/shared";
import { useLocation } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { Link as RRLink } from "react-router-dom";
import { Menu as MenuIcon } from "react-feather";

export interface LayoutProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const LayoutContainer = styled.div`
  display: grid;
  grid-template-areas:
    "nav"
    "content";
  grid-template-rows: 4rem 1fr;
  min-height: 100vh;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  grid-area: nav;
  box-shadow: ${theme.shadows.md};
  overflow: hidden;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const Sidebar = styled.nav`
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 2;
`;

const SidebarItem = styled(RRLink)<{ active?: boolean }>`
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.gray[50]};
  }
  ${(props) =>
    props.active &&
    css`
      background-color: ${theme.colors.gray[50]};
      &:hover {
        background-color: ${theme.colors.gray[100]};
      }
    `}
`;

const NavSpacer = styled.div`
  flex: 1;
`;

const Footer = styled.nav`
  grid-area: footer;
  box-shadow: ${theme.shadows["2xl"]};
  font-size: ${theme.fontSizes.sm};
  background-color: #fff;
  margin-top: 1em;
`;

const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "first"
    "second"
    "third";
  @media (min-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "first second third";
    grid-gap: 1em;
  }
  grid-gap: 1.5em;
  padding: 1em 0;
`;

const FooterSection = styled.div`
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  > div {
    margin: 0.5em;
  }
  align-items: center;
  @media (min-width: ${theme.breakpoints[0]}) {
    align-items: flex-start;
    > div {
      text-align: left;
    }
  }
`;

const Content = styled.div`
  grid-area: content;
  padding-top: 1em;
  overflow-x: hidden;
  overflow-y: scroll;
  background: url("/background-image.svg");
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentInner = styled.div`
  flex: 1 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
`;

const Logo = styled.img`
  height: 2rem;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  max-width: 40%;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 1em;
  > * + * {
    margin-left: 0.5em;
  }
`;

const FooterLinks = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @media (min-width: ${theme.breakpoints[0]}) {
    flex-direction: column;
    justify-content: center;
  }
`;

const FooterLink = styled(CLink)`
  &:not(:last-child) {
    margin-bottom: 0;

    @media (min-width: ${theme.breakpoints[0]}) {
      margin-bottom: 3em;
      margin-right: 0;
    }
  }
`;

const CloudAfricaCopyright = styled(Box)`
  display: flex;
  justify-content: center;

  @media (min-width: ${theme.breakpoints[0]}) {
    justify-content: flex-end;
    margin: 0 2em;
  }
`;

const MI = styled(MenuItem)<{ href?: string }>``;

export const Layout = forwardRef<HTMLDivElement, LayoutProps>(
  ({ children, className, ...props }, ref) => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { user, loading, profileComplete } = useContext(UserContext);
    return (
      <>
        <LayoutContainer
          {...props}
          ref={ref}
          className={classnames("", {}, className)}
        >
          <Nav>
            <LogoContainer>
              <Link to="/dashboard" data-testid="main-logo">
                <Logo src={logo} />
              </Link>
              <MenuIcon
                style={{ cursor: "pointer" }}
                onClick={() => setSidebarOpen(true)}
                data-testid="main-menu"
              />
            </LogoContainer>

            <NavSpacer />
            <UserInfo data-testid="userinfo">
              <Menu>
                <MenuButton size="xs" as={Button}>
                  <span>{user.email}</span>
                </MenuButton>
                &nbsp;
                <MenuList>
                  <MI
                    as="a"
                    href="/api/identity/logout"
                    data-testid="userinfo-logout"
                  >
                    <Icon name="unlock" marginRight={2} />
                    Logout
                  </MI>
                </MenuList>
              </Menu>
              <Avatar size="xs" />
            </UserInfo>
          </Nav>
          <Content>
            <ContentInner>
              {loading.loaded && !profileComplete && (
                <Alert status="warning" marginBottom="1em">
                  <AlertIcon />
                  <p>
                    Your profile information is incomplete, please complete
                    it&nbsp;<Link to="/profile">here</Link>.
                  </p>
                </Alert>
              )}
              {children}{" "}
            </ContentInner>
            <Footer>
              <FooterContainer>
                <Stack align="flex-start" gridArea="first">
                  <FooterSection>
                    <div>
                      <Logo src={logo} />
                    </div>
                    <div>
                      <CLink
                        isExternal
                        color="blue.400"
                        href="https://ispa.org.za/code-of-conduct/"
                        target="_blank"
                      >
                        <img
                          alt="ISPA"
                          src={ispaLogo}
                          style={{ height: "3.1em" }}
                        />
                      </CLink>
                    </div>
                    <div>
                      <CLink
                        isExternal
                        color="blue.500"
                        href="https://ispa.org.za/code-of-conduct/"
                        target="_blank"
                      >
                        Member of ISPA
                      </CLink>
                      <p>Internet Service Providers' Association (ISPA)</p>
                      <p>Address: PO Box 518, Noordwyk, 1687</p>
                      <p>Telephone: 010 500 1200</p>
                    </div>
                    <div>
                      <CLink
                        data-testid="ispa-logo"
                        isExternal
                        color="blue.500"
                        href="https://ispa.org.za/tdn/"
                        target="_blank"
                      >
                        ISPA Take Down Notice Information
                      </CLink>

                      <p>
                        Email:{" "}
                        <CLink
                          isExternal
                          color="blue.500"
                          href="mailto:complaints@ispa.org.za"
                        >
                          complaints@ispa.org.za
                        </CLink>
                      </p>
                    </div>
                  </FooterSection>
                </Stack>
                <FooterLinks gridArea="second">
                  <FooterLink
                    isExternal
                      href="https://support.cloudafrica.net/support/solutions/101000253059"
                    target="_blank"
                  >
                    Quickstart
                  </FooterLink>
                  <FooterLink
                    isExternal
                    href="https://support.cloudafrica.net/support/home"
                    target="_blank"
                  >
                    Contact Us
                  </FooterLink>
                  <FooterLink
                    isExternal
                      href="https://support.cloudafrica.net/support/solutions/101000253059"
                    target="_blank"
                  >
                    FAQ's
                  </FooterLink>
                  <FooterLink
                    isExternal
                      href="https://support.cloudafrica.net/support/solutions/articles/101000466086-bigstorage-products-pricing"
                    target="_blank"
                  >
                    Products & Pricing
                  </FooterLink>
                </FooterLinks>
                <CloudAfricaCopyright gridArea="third">
                  <Stack
                    display="inline-flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <CLink
                      isExternal
                      href="https://cloudafrica.net"
                      target="_blank"
                    >
                      <img
                        alt="CloudAfrica"
                        src={caLogo}
                        style={{ height: "7em", width: "7em" }}
                      />
                    </CLink>
                    <p>A CloudAfrica Offering</p>
                    <br />
                    <p>Copyright @ 2017 Cloudafrica.net</p>
                    <p>All rights reserved</p>
                  </Stack>
                </CloudAfricaCopyright>
              </FooterContainer>
            </Footer>
          </Content>
        </LayoutContainer>
        <Drawer
          isOpen={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          placement="left"
          size="xs"
        >
          <DrawerContent>
            <Sidebar>
              <LogoContainer style={{ paddingTop: 0 }}>
                <a href="www.bigstorage.io">
                  <Logo src={logo} />
                </a>
                <MenuIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setSidebarOpen(false)}
                />
              </LogoContainer>

              <SidebarItem
                active={location.pathname.includes("dashboard")}
                to="/dashboard"
                data-testid="sidebar-dashboard-link"
              >
                Dashboard
              </SidebarItem>
              <SidebarItem
                active={location.pathname.includes("access")}
                to="/keys"
              >
                Keys
              </SidebarItem>
              <SidebarItem
                active={location.pathname.includes("support")}
                to="/support/contact-us"
              >
                Support
              </SidebarItem>
              <SidebarItem
                active={location.pathname.includes("billing")}
                to="/billing/orders"
              >
                Billing
              </SidebarItem>
              <SidebarItem
                active={location.pathname.includes("profile")}
                to="/profile"
                data-testid="sidebar-profile-link"
              >
                Profile
              </SidebarItem>
              <SidebarItem
                active={location.pathname.includes("policies")}
                to="/policies"
              >
                Policies
              </SidebarItem>
            </Sidebar>
          </DrawerContent>
        </Drawer>
      </>
    );
  }
);
