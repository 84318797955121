import React, { forwardRef, useCallback, useState } from "react";
import classnames from "classnames";
import {
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Stack,
  Button,
  Heading,
  Alert
} from "@chakra-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import logo from "../../logo.svg";
import { useLoading } from "../../hooks/useLoading";
import { resetRequest } from "../../lib/api/user";
import { ValidationError } from "../../lib/api/base";
import {
  UserFlowPage,
  UserFlowContainer,
  Logo,
  Link,
  UserFlowForm
} from "../shared";
import { FormikField } from "../shared/forms";
import { ValidEmail } from "../../lib/schemata";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Required")
    .test(ValidEmail)
});

export interface ResetRequestProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const ResetRequest = forwardRef<HTMLDivElement, ResetRequestProps>(
  ({ children, className, ...props }, ref) => {
    const [submitError, setSubmitError] = useState<{ message?: string }>({});
    const [success, setSuccess] = useState<{ message?: string }>({});
    const [loading, , withLoading] = useLoading<void>();

    const handleSubmit = useCallback(
      values => {
        withLoading(async () => {
          try {
            setSubmitError({});
            await resetRequest(values);
            setSuccess({
              message:
                "Your password reset request was successful, please check your inbox for a reset link."
            });
          } catch (e) {
            if (e instanceof ValidationError) {
              return setSubmitError({ message: e.message });
            }
            return setSubmitError({
              message: "An unexpected error has occurred"
            });
          }
        });
      },
      [withLoading]
    );

    return (
      <UserFlowPage
        {...props}
        ref={ref}
        className={classnames("", {}, className)}
      >
        <UserFlowContainer rounded="lg">
          <Formik
            initialValues={{ email: "" }}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            <UserFlowForm>
              <Stack spacing={4}>
                <Logo src={logo} />
                <Heading size="lg">Password Reset</Heading>
                {submitError.message && (
                  <Alert status="error">{submitError.message}</Alert>
                )}
                {success.message && (
                  <Alert status="success">{success.message}</Alert>
                )}
                <FormikField name="email" isRequired>
                  {({ field }) => (
                    <InputGroup>
                      <InputLeftElement>
                        <Icon name="lock" />
                      </InputLeftElement>
                      <Input {...field} placeholder="Email" />
                    </InputGroup>
                  )}
                </FormikField>
                <Button
                  type="submit"
                  isLoading={loading.loading}
                  variantColor="yellow"
                >
                  Request password reset
                </Button>

                <span>
                  New to us <Link to="/register">register here</Link>
                </span>
                <span>
                  Already have an account <Link to="/login">login here</Link>
                </span>
              </Stack>
            </UserFlowForm>
          </Formik>
        </UserFlowContainer>
      </UserFlowPage>
    );
  }
);
